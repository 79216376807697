import { useCallback, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import { BlobContent } from "@eolas-medical/core";
import useBlobViewer from "../useBlobViewer";
import { PDF_TRON_SOURCE } from "config/pdftron.constants";

interface UseDocumentViewer {
  document: BlobContent;
}

const useDocumentViewer = ({ document }: UseDocumentViewer) => {
  const { onFileError, onFileLoaded, onViewerLoadError } = useBlobViewer();
  const viewerContainer = useRef<HTMLDivElement>(null);
  const documentViewer = useCallback(
    () =>
      viewerContainer.current && document && document.url
        ? WebViewer(
            {
              path: PDF_TRON_SOURCE,
              licenseKey: process.env.REACT_APP_PDF_TRON,
              initialDoc: document.url,
              disabledElements: [
                "ribbons",
                "toolsHeader",
                "toggleNotesButton",
                "selectToolButton",
                "textHighlightToolButton",
                "textUnderlineToolButton",
                "textSquigglyToolButton",
                "textStrikeoutToolButton",
                "linkButton",
              ],
            },
            viewerContainer.current as HTMLDivElement,
          )
            .then(({ UI, Core }) => {
              UI.addEventListener(UI.Events.DOCUMENT_LOADED, onFileLoaded);
              UI.addEventListener(UI.Events.LOAD_ERROR, onViewerLoadError);
              Core.disableEmbeddedJavaScript();
            })
            .catch(onFileError)
        : null,
    [document, onFileError, onFileLoaded, onViewerLoadError],
  );

  useEffect(() => {
    if (!document || !viewerContainer.current) return;

    documentViewer();
  }, [document, documentViewer]);

  return { viewerContainer, onFileLoaded, onFileError };
};

export default useDocumentViewer;
