import { errorStore } from "Stores/ErrorStore";
import { BaseAppRoute } from "Utilities/types";
import { CHAPTER_SUBTYPE, GUIDANCE_TYPE } from "./constants";
import { History } from "history";
import {
  AnalyticsEvents,
  GeneralCategory,
  GetSummariesReturn,
  GuidanceReference,
  Maybe,
  NiceSummaryItem,
} from "@eolas-medical/core";
import { Core } from "@pdftron/webviewer";
import { trackEvent } from "API/Analytics";

export const isGuidanceUrl = (url: URL) => {
  const type = url.pathname.split("/")[1]?.toLowerCase();
  const subtype = getSubtype(url);
  return type === GUIDANCE_TYPE && (!subtype || subtype === CHAPTER_SUBTYPE);
};

export const getSubtype = (url: URL) => {
  return url.pathname.split("/")[3]?.toLowerCase();
};

export const isLinkToSpecificSection = (subtype: string | null) => {
  return subtype === CHAPTER_SUBTYPE;
};

export const extractGuidanceNumber = (url: URL) => {
  return url.pathname.split("/")[2]?.toUpperCase();
};

export const captureGuidanceError = (url: URL, guidanceNumber: string) => {
  errorStore.captureError({
    error: "Error extracting guidance number from URL",
    source: "user",
    data: { url, guidanceNumber },
  });
};

export function navigateToGuidancePage(
  history: History,
  guidanceNumber: string | null,
  subtype: string | null,
  bookmarkNames: { parent: string; child: string } | null,
) {
  if (!guidanceNumber) {
    return;
  }
  if (isLinkToSpecificSection(subtype)) {
    if (bookmarkNames) {
      history.push({
        pathname: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${guidanceNumber}`,
        state: { bookmarkNames },
      });
      return;
    }
  }

  history.push({
    pathname: `/${BaseAppRoute.knowledge}/niceGuidelines/viewer/${guidanceNumber}`,
  });
}

export const makeAllGuidelinesCustomCategory = (data: GetSummariesReturn): GeneralCategory => {
  const allGuidanceReferences = data.summaries.map((summary) => {
    return {
      guidanceId: summary.id,
    };
  });
  const customCategory = {
    id: "",
    title: "All Guidelines",
    childrenType: "guidanceReference",
    children: allGuidanceReferences,
  } as GeneralCategory;

  return customCategory;
};

export const constructSummaryArray = ({
  summariesData,
  items,
}: {
  summariesData: NiceSummaryItem[] | undefined;
  items: GuidanceReference[];
}) => {
  if (!summariesData) return;
  const summaries: NiceSummaryItem[] = [];
  items.forEach((guidanceReference) => {
    const match = summariesData.find(
      (summaryItem) => summaryItem.id === guidanceReference.guidanceId,
    );
    if (match) {
      summaries.push(match);
    } else {
      errorStore.captureError({
        error: new Error(
          "Could not find matching summary item based on guidance reference id of Nice Guideline",
        ),
        source: "user",
        data: { items },
      });
    }
  });
  return summaries;
};

export const getChildBookmark = (bookmarkChildren: Core.Bookmark[], bookmarkChildName: string) => {
  const possibleMatchChild = bookmarkChildren.find((bookmark) => {
    return findChildBookmark(bookmark, bookmarkChildName) !== null;
  });

  if (
    possibleMatchChild &&
    !possibleMatchChild.getName().toLowerCase().includes(bookmarkChildName)
  ) {
    const matchChild = possibleMatchChild.getChildren().find((child) => {
      return child.getName().toLowerCase().includes(bookmarkChildName);
    });
    return matchChild;
  } else {
    return possibleMatchChild;
  }
};

export const findChildBookmark = (
  bookmark: Core.Bookmark,
  childName: string,
): Core.Bookmark | null => {
  if (bookmark.getName().toLowerCase().includes(childName)) {
    return bookmark;
  }

  const children = bookmark.getChildren();
  for (const child of children) {
    const foundChild: Core.Bookmark | null = findChildBookmark(child, childName);
    if (foundChild) {
      return foundChild;
    }
  }

  return null;
};

/* These helper functions manipulate strings to obtain the parent and child bookmarks
 * for auto scrolling to section/subsection of a NICE item within Pdftron Webviewer.
 * Where these are used, there should always be a fallback to open the guideline at page 1.
 */

export const getBookmarkNames = (url: URL) => {
  // format of parent (top level section) bookmark name: "Recommendations"
  // format of child (subsection) bookmark name: "1.13 Control of cardiovascular risk"
  const parentIdentifier = url.pathname.split("/")[4]?.toLowerCase() ?? "";
  const childKebabCase = url.toString().split("#")[1]?.toLowerCase();
  const child = childKebabCase?.split("-").join(" ") ?? "";

  // sometimes the id of the parent section is kebab case, in the format '1-Recommendations'
  if (parentIdentifier.includes("-")) {
    const parent = parentIdentifier?.split("-").slice(1)?.join(" ") ?? "";
    return { parent, child };
  } else {
    return { parent: parentIdentifier, child };
  }
};

export const trackNiceSearch = (query: string, results: (GeneralCategory | NiceSummaryItem)[]) => {
  trackEvent(AnalyticsEvents.NICE_SEARCH_PERFORMED, { query, hitCount: results.length });
};

export const processId = (id: Maybe<string>) => {
  const fragments = id?.toLowerCase().split("-");
  if (fragments && fragments.length > 0 && !isNaN(Number(fragments[fragments.length - 1]))) {
    fragments.pop();
  }
  return fragments?.join(" ");
};
