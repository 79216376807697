import React from "react";
import { EolasFile } from "@eolas-medical/core";
import { getPreSignUrl } from "Pages/Spaces/functions/helpers";

export const DocumentCacheEvents = {
  update: "update-pdf-cache",
  delete: "delete-pdf-cache",
} as const;

type ValueOf<T> = T[keyof T];

export const createPDFEvent = (
  type: ValueOf<typeof DocumentCacheEvents>,
  detail?: { key: string; value?: string },
) => {
  return new CustomEvent(type, { detail });
};

export const deletePDFCacheItem = (key?: string | null) => {
  if (!key) return;

  window.dispatchEvent(createPDFEvent("delete-pdf-cache", { key }));
};

export const updatePDFCacheItem = (key: string, value: string) => {
  window.dispatchEvent(createPDFEvent("update-pdf-cache", { key, value }));
};

class DocumentCache {
  timeoutCache: Record<string, NodeJS.Timeout>;
  cache: Record<string, string>;
  // clear the cache after 5 minutes
  CACHE_TIME = 1000 * 60 * 5;

  constructor() {
    this.cache = {};
    this.timeoutCache = {};
  }

  getItem(key: string) {
    return this.cache[key];
  }

  deleteEntry(key: string) {
    delete this.cache[key];
  }

  setItem(key: string, value: string) {
    this.cache[key] = value;

    const previousTimeout = this.timeoutCache[key];

    if (previousTimeout) {
      clearTimeout(previousTimeout);
    }

    const timeout = setTimeout(() => {
      this.deleteEntry(key);
    }, this.CACHE_TIME);

    this.timeoutCache[key] = timeout;
  }
}

const documentCache = new DocumentCache();

export const updateDocumentCache = (file: EolasFile) => {
  if (file.type !== "pdf") return;

  deletePDFCacheItem(file.key);
};

export const useDocumentCache = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDeleteCacheItem = React.useCallback((event: any) => {
    documentCache.deleteEntry(event.detail.key);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateCacheItem = React.useCallback((event: any) => {
    documentCache.setItem(event.detail.key, event.detail.value);
  }, []);

  React.useEffect(() => {
    window.addEventListener(DocumentCacheEvents.delete, onDeleteCacheItem);
    window.addEventListener(DocumentCacheEvents.update, onUpdateCacheItem);

    return () => {
      window.removeEventListener(DocumentCacheEvents.delete, onDeleteCacheItem);
      window.removeEventListener(DocumentCacheEvents.update, onUpdateCacheItem);
    };
  }, [onDeleteCacheItem, onUpdateCacheItem]);
};

const isS3Key = (key: string) => {
  // S3 keys do not generally have a protocol or domain, so if these are present, it's likely not a key.
  const forbiddenPatterns = new RegExp("^(https?:\\/\\/|www\\.)", "i");
  return !forbiddenPatterns.test(key);
};

const generateS3Url = async ({
  fileId,
  key,
  versionNo,
  draftId,
}: {
  fileId: string;
  key: string;
  versionNo?: number;
  draftId?: string;
}) => {
  if (!isS3Key(key)) {
    return key;
  }
  return getPreSignUrl({ fileId, versionNo, draftId });
};

export const getPDFUrl = ({
  fileId,
  key,
  versionNo,
  draftId,
}: {
  fileId: string;
  key?: string | null;
  versionNo?: number;
  draftId?: string;
}): Promise<string> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (!key) {
      return reject("Missing file key.");
    }

    // return value from the cache
    const cacheValue = documentCache.getItem(key);

    if (cacheValue) {
      return resolve(cacheValue);
    }

    // fetch pdf url and update the cache
    try {
      const s3Url = await generateS3Url({ fileId, key, versionNo, draftId });

      documentCache.setItem(key, s3Url);

      resolve(s3Url);
    } catch (err) {
      reject(err);
    }
  });
};
