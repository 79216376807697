import { ContentRepositoryId, ContentSection } from "modules/contentRepository/types";
import {
  ChildReference,
  ContentItem,
  Maybe,
  isEolasShadowCopyFile,
  sectionStore,
} from "@eolas-medical/core";
import { mapToContentItem, mapToContentSection } from "modules/contentRepository/helpers";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { useSpacesContext } from "modules/spaces";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { makeShouldIncludeSectionHelpers } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { useQuery } from "@tanstack/react-query";

export enum ContentType {
  Section = "section",
  Item = "item",
}

export enum ValidFileChildren {
  deepLink = "deepLink",
  file = "file",
}

export type UseGetAllContentRepositoryProps = {
  contentRepositoryId: ContentRepositoryId;
  contentType: ContentType;
};

const blankData: {
  finalItemList: Array<ContentItem>;
  itemMap: Record<string, ContentItem>;
  finalSectionItemList: Array<ContentSection>;
} = { finalItemList: [], finalSectionItemList: [], itemMap: {} };

export const useGetAllContentRepository = ({
  contentRepositoryId,
  contentType,
}: UseGetAllContentRepositoryProps) => {
  const { isAdmin: isInAdminMode } = useSpacesContext();
  const { activeTab } = useEolasNavigation();
  const adminStatus = useGetAdminStatus({ activeTab });
  const limitedAccess = useGetLimitedAccess({ activeTab });

  const { data } = useQuery({
    queryKey: [
      "getAllContent",
      contentType,
      contentRepositoryId,
      limitedAccess,
      isInAdminMode,
      adminStatus,
    ],
    queryFn: async () => {
      let shouldApplySectionFilterForTree = isInAdminMode && adminStatus === "limitedAdmin";
      let topLevelFilterFn: (sectionId: string) => boolean = () => true;

      if (shouldApplySectionFilterForTree) {
        const { filterFnForChildren, shouldCurrentSectionAllowAdminRights } =
          makeShouldIncludeSectionHelpers(limitedAccess, contentRepositoryId);
        if (shouldCurrentSectionAllowAdminRights) {
          shouldApplySectionFilterForTree = false;
        } else {
          topLevelFilterFn = filterFnForChildren;
        }
      }

      const finalItemList: Array<ContentItem> = [];
      const originalsOfShadowCopies: Array<ContentItem> = [];
      const finalSectionItemList: Array<ContentSection> = [];
      const childrenOrder = sectionStore
        .getChildrenOrder(contentRepositoryId)
        .filter(({ id }) => topLevelFilterFn(id));

      if (!childrenOrder.length) {
        return { finalItemList, finalSectionItemList };
      }

      await addToContentList({
        currentSectionId: contentRepositoryId,
        childrenOrder,
        finalItemList,
        finalSectionItemList,
        originalsOfShadowCopies,
        contentType,
        isInAdminMode,
        shouldApplySectionFilterForTree,
        limitedAccess,
      });

      const itemMap = finalItemList.reduce<Record<string, string>>(
        (acc, current) => ({
          ...acc,
          [current.id]: current.id,
        }),
        {},
      );

      return { finalItemList, finalSectionItemList, itemMap };
    },
  });

  return data ?? blankData;
};

const addToContentList = async ({
  currentSectionId,
  childrenOrder,
  finalItemList,
  finalSectionItemList,
  originalsOfShadowCopies,
  contentType,
  isInAdminMode,
  shouldApplySectionFilterForTree,
  limitedAccess,
}: {
  currentSectionId: string;
  childrenOrder: ChildReference[];
  finalItemList: Array<ContentItem>;
  finalSectionItemList: Array<ContentSection>;
  originalsOfShadowCopies: Array<ContentItem>;
  contentType: ContentType;
  isInAdminMode: boolean;
  shouldApplySectionFilterForTree: boolean;
  limitedAccess: string[];
}) => {
  let shouldIncludeSection: (sectionId: string) => boolean = () => true;
  let nextLevelShouldApplySectionFilterForTree = false;
  if (shouldApplySectionFilterForTree) {
    const { filterFnForChildren, shouldCurrentSectionAllowAdminRights } =
      makeShouldIncludeSectionHelpers(limitedAccess, currentSectionId);
    if (!shouldCurrentSectionAllowAdminRights) {
      shouldIncludeSection = filterFnForChildren;
      nextLevelShouldApplySectionFilterForTree = true;
    }
  }
  for (const child of childrenOrder) {
    if (!child) {
      continue;
    }
    if (contentType === ContentType.Section) {
      const childReference = sectionStore.getChildReferenceOfSection(child.id);
      if (childReference && shouldIncludeSection(childReference.id)) {
        const mappedSection = mapToContentSection(childReference);
        finalSectionItemList.push(mappedSection);
      }
      const nextLevelChildrenOrder = sectionStore.getChildrenOrder(child.id);
      await addToContentList({
        currentSectionId: child.id,
        childrenOrder: nextLevelChildrenOrder,
        finalItemList,
        finalSectionItemList,
        originalsOfShadowCopies,
        contentType,
        isInAdminMode,
        shouldApplySectionFilterForTree: nextLevelShouldApplySectionFilterForTree,
        limitedAccess,
      });
    }
    if (contentType === ContentType.Item) {
      if (!isValidFileChild(child.type)) {
        const nextLevelChildrenOrder = sectionStore
          .getChildrenOrder(child.id)
          .filter(({ id }) => shouldIncludeSection(id));
        await addToContentList({
          currentSectionId: child.id,
          childrenOrder: nextLevelChildrenOrder,
          finalItemList,
          finalSectionItemList,
          originalsOfShadowCopies,
          contentType,
          isInAdminMode,
          shouldApplySectionFilterForTree: nextLevelShouldApplySectionFilterForTree,
          limitedAccess,
        });
        continue;
      }

      const file = await contentDbStore.getItem(child.id);

      if (!file) {
        continue;
      }

      if (child.type === ValidFileChildren.deepLink && isEolasShadowCopyFile(file)) {
        const originalFile = await getOriginalFromShadowCopy(file, false);
        if (!originalFile) {
          continue;
        }

        if (finalItemList.some(({ id }) => id === originalFile.id && !isInAdminMode)) {
          continue;
        }

        originalsOfShadowCopies.push(mapToContentItem(originalFile));
      }

      if (originalsOfShadowCopies.some(({ id }) => id === file.id) && !isInAdminMode) {
        continue;
      }

      finalItemList.push(mapToContentItem(file));
    }
  }
};

export const isValidFileChild = (type: Maybe<string>): type is ValidFileChildren => {
  if (!type) {
    return false;
  }
  return Object.values<string>(ValidFileChildren).includes(type);
};
