import {
  AnalyticsEvents,
  AppLevelSection,
  DeeplinkTappedPayload,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { useMediaQuery } from "Hooks";
import { useAutoLogin } from "Pages/Authentication/pages/AutoLogin/useAutologin";
import { expectNever } from "Utilities/helpers";
import { LDFlagNames } from "Utilities/types";
import {
  extractLoginToken,
  extractNewsFeedParams,
  getDeeplinkEntityId,
  getDeeplinkEntityType,
  getDeeplinkMainSectionIdentity,
  redirectToLinkedDocument,
} from "deeplinking/functions/helpers";
import { isSupportedSpaceContentDeepLinkType } from "deeplinking/functions/typeguards";
import { DeepLinkParams, DeeplinkError } from "deeplinking/types/general.types";
import { useSpacesContext } from "modules/spaces";
import React, { useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

export const useHandleSpacesDeeplink = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { autoLogin, isLoading: isLoggingInWithToken } = useAutoLogin({
    callbackUrl: pathname + search,
  });
  const didFireAnalyticsEvent = React.useRef(false);

  const media = useMediaQuery();
  const { type } = useParams<DeepLinkParams>();

  const { flags } = useLaunchDarkly();
  const { encodedSelectedSpaceName, selectedSpace, isInSpace } = useSpacesContext();
  const { toggleDrawerHidden } = useEolasNavigation();
  const isInitialLoadingSpaceData = isInSpace && !selectedSpace;
  const [isLoading, setIsLoading] = React.useState(true);
  const [deeplinkError, setDeeplinkError] = React.useState<DeeplinkError | null>(null);
  const [shouldDisplayDirectToMobile, setShouldDisplayDirectToMobile] = React.useState(false);
  const isMobile = media === "xs";
  const shouldDirectToMobileFlag =
    flags[LDFlagNames.SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE] ?? false;

  const setDisplayDirectToMobile = React.useCallback(() => {
    setShouldDisplayDirectToMobile(true);
    toggleDrawerHidden(true);
  }, [toggleDrawerHidden]);

  useEffect(() => {
    if (isInitialLoadingSpaceData || isLoggingInWithToken) {
      // If this is true, the spaces context is still loading and we should wait for it to finish before handling the deeplink
      return;
    }

    if (!userStore.userSession.isLoggedIn) {
      const maybeLoginToken = extractLoginToken(search);
      if (maybeLoginToken) {
        autoLogin(maybeLoginToken);
        return;
      }
    }

    if (type && isSupportedSpaceContentDeepLinkType(type)) {
      if (didFireAnalyticsEvent.current === false) {
        didFireAnalyticsEvent.current = true;
        trackEvent<DeeplinkTappedPayload>(AnalyticsEvents.DEEPLINK_TAPPED, {
          entityId: getDeeplinkEntityId(search, type),
          type: getDeeplinkEntityType(search, type),
          mainSectionType: getDeeplinkMainSectionIdentity(type),
        });
      }

      switch (type) {
        case AppLevelSection.newsFeed:
          {
            const newsFeedParams = extractNewsFeedParams(search, type);
            if (newsFeedParams) {
              setIsLoading(false);
              if (isMobile && shouldDirectToMobileFlag) {
                setDisplayDirectToMobile();
              } else {
                redirectToLinkedDocument({
                  basePath: "/spaces",
                  legacyType: type,
                  history,
                  searchParams: search,
                });
              }
            } else {
              setIsLoading(false);
              setDeeplinkError("invalidParams");
              eolasLogger.error(new Error("News feed deeplink is missing or has invalid params"), {
                search,
              });
            }
          }
          break;
        // Content repositories
        case "null":
        case AppLevelSection.admissionGuides:
        case AppLevelSection.clinicalPathways:
        case AppLevelSection.emergencyActionCards:
        case AppLevelSection.equipmentLocations:
        case AppLevelSection.guidelines:
        case AppLevelSection.howTo:
        case AppLevelSection.rotas:
        case AppLevelSection.surveys:
        case AppLevelSection.wellbeing:
        case AppLevelSection.researchAndAudit:
        case AppLevelSection.clinicalDocuments:
        case AppLevelSection.policyDocuments:
        case AppLevelSection.patientLeaflets:
        case AppLevelSection.checklists:
        case AppLevelSection.contacts:
        case AppLevelSection.importantLinks:
        case AppLevelSection.educationPortal:
          setIsLoading(false);
          if (isMobile && shouldDirectToMobileFlag) {
            setDisplayDirectToMobile();
          } else {
            redirectToLinkedDocument({
              basePath: "/spaces",
              legacyType: type,
              history,
              searchParams: search,
            });
          }
          break;
        case AppLevelSection.activityFeed:
          setIsLoading(false);
          // activity feed is only available on mobile so we always redirect to mobile
          setDisplayDirectToMobile();
          break;
        default: {
          // If the below line throws a type error, it means that the type is not handled in the switch statement above
          expectNever(type);
        }
      }
    } else {
      setIsLoading(false);
      setDeeplinkError("unSupportedType");
    }

    return () => {
      toggleDrawerHidden(false);
    };
  }, [
    search,
    type,
    history,
    isMobile,
    selectedSpace,
    isInitialLoadingSpaceData,
    encodedSelectedSpaceName,
    setDisplayDirectToMobile,
    toggleDrawerHidden,
    shouldDirectToMobileFlag,
    autoLogin,
    isLoggingInWithToken,
  ]);

  return {
    isLoading,
    hasError: !!deeplinkError,
    error: deeplinkError,
    shouldDisplayDirectToMobile: shouldDisplayDirectToMobile,
    isMobileBrowser: isMobile,
    type,
    searchString: search,
  };
};
