import Fuse from "fuse.js";
import { useCallback, useMemo, useRef, useState } from "react";
import { sectionStore, EolasMainSection, eolasLogger, EolasFile } from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { eolasFileNormaliser, Timeout } from "Utilities/helpers";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { useContentForSearch } from "shared/hooks/useContentForSearch";

const files: EolasFile[] = [];

/**
 * @TODO: remove this hook when removing highlights code (feature switched off)
 */
export const useLegacyMasterSearch = (showAll?: boolean) => {
  const { flags } = useLaunchDarkly();
  const [masterSearchValue, setMasterSearchValue] = useState("");
  const checklistsList = sectionStore.checklistTemplatesList;

  const filteredFiles = useMemo(() => {
    if (showAll && masterSearchValue.length < 2) return files;

    if (masterSearchValue.length < 2) {
      return [];
    }

    const activeSectionIDs = [
      ...sectionStore.getMainSectionList("department").map((childRef) => childRef.id),
      ...sectionStore.getMainSectionList("hospital").map((childRef) => childRef.id),
    ];

    const fuseFilesConfig = flags[LDFlagNames.UPDATED_SEARCH]
      ? {
          ignoreLocation: false,
          minMatchCharLength: 2,
          threshold: 0.4,
          ignoreFieldNorm: true,
          distance: 200,
          keys: ["searchField"],
        }
      : {
          ignoreLocation: true,
          threshold: 0.15,
          distance: 90,
          keys: ["searchField"],
        };

    const fuseFiles = new Fuse(files, {
      ...fuseFilesConfig,
    });

    const foundFiles = fuseFiles
      .search(masterSearchValue)
      .map((res) => res.item)
      .filter(({ mainSectionID, searchField, updatedAt }) => {
        if (!activeSectionIDs.includes(mainSectionID as string)) return false;
        if (!searchField || !updatedAt) return false;

        return true;
      });

    return foundFiles;
  }, [showAll, masterSearchValue, flags]);

  const filteredChecklists = useMemo(() => {
    if (showAll && masterSearchValue.length < 2) return checklistsList;
    if (masterSearchValue.length < 2) {
      return [];
    }
    const optionsForChecklists = {
      includeScore: true,
      threshold: 0.15,
      distance: 90,
      keys: ["checklistName"],
    };

    const fuseChecklists = new Fuse(checklistsList, optionsForChecklists);
    const foundChecklists = fuseChecklists.search(masterSearchValue).map((res) => res.item) ?? [];

    return foundChecklists;
  }, [masterSearchValue, showAll, checklistsList]);

  const clearMasterSearch = useCallback(() => {
    setMasterSearchValue("");
  }, [setMasterSearchValue]);

  const onMasterSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setMasterSearchValue(inputValue);
    },
    [setMasterSearchValue],
  );

  return {
    filteredFiles,
    masterSearchValue,
    clearMasterSearch,
    filteredChecklists,
    onMasterSearchChange,
  };
};

/**
 * This should only be used for MiniApps or legacy content repos in space or org tab. The debounce
 * logic happens in the hook.
 * @TODO: update search logic and search component to shared system across the app (this is feature agnostic)
 */
export const useSearchMainSectionFiles = (mainSectionType: EolasMainSection) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredFiles, setFilteredFiles] = useState<EolasFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const timeoutRef = useRef<Timeout | null>(null);
  const mainSectionChildRef = sectionStore.getChildReferenceByMainSectionType(mainSectionType);
  const mainSectionId = mainSectionChildRef?.id ?? "";
  const { flags } = useLaunchDarkly();

  if (!mainSectionId) {
    eolasLogger.error("useSearchMainSectionFiles: no main section found for main section type", {
      mainSectionType,
    });
  }

  const idsToInclude = useContentForSearch(mainSectionId);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchValue(text);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (text.length < 2) {
      setFilteredFiles([]);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    timeoutRef.current = setTimeout(async () => {
      const masterSearchResults = await localSearchStore.search({
        term: text,
        shouldSearchOrganisation: sectionStore.organisationActiveHospitalTab,
        ldFlags: flags,
        idsToInclude,
        isInAdminMode: sectionStore.isAdmin,
      });
      setFilteredFiles(masterSearchResults.map((item) => eolasFileNormaliser(item).file));
      setIsLoading(false);
    }, 800);
  };

  return {
    searchValue,
    filteredFiles,
    onSearchChange,
    isLoading,
  };
};
