import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";

import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  NationalResourcesClickPayload,
  NationalResourceEntityType,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Modal } from "UIKit";

import { snakeCase } from "lodash";
import useNationalBodies from "modules/nationalResources/data/useNationalBodies";
import { SearchSortList } from "shared/components/Lists";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { CommunitySectionType } from "../ClinicalCalculators/types";
import { trackEvent } from "API/Analytics";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { useMainSectionVisit } from "Hooks";
import { PatientLeafletQRModal } from "Pages/Spaces/pages/Space/pages/MiniApp/PatientLeaflets/components";
import { BaseAppRoute } from "Utilities/types";

const PatientLeaflets: React.FC<RouteComponentProps> = observer(() => {
  const [searchInput, setSearchInput] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchResults, setSearchResults] = useState<any>([]);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const [modal, setModal] = useState<React.ReactNode>(null);

  useMainSectionVisit({
    mainSectionId: CommunityLevelSection.communityPatientLeaflets,
  });

  const history = useHistory();
  const { nationalBodies, gettingNationalBodies } = useNationalBodies({
    entityType: NationalResourceEntityType.LEAFLET,
  });
  const { searchNationalResources, searchingNationalResources } = useSearchNationalResources();

  const { t } = useTranslation();

  const handleClickBody = useCallback(
    ({ name }: EolasFile) => {
      const snakeCaseBody = snakeCase(name!);
      history.push({
        pathname: `/${BaseAppRoute.knowledge}/communityPatientLeaflets/${snakeCaseBody}`,
        state: { body: name },
      });
    },
    [history],
  );

  const handleClickLeaflet = (leaflet: EolasFile) => {
    trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
      nationalResourceType: NationalResourceEntityType.LEAFLET,
      resourceName: leaflet.name,
    } as NationalResourcesClickPayload);
    setModal(<PatientLeafletQRModal file={leaflet} />);
  };

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
    setShowSearchResult(false);
  }, []);

  const handleSearchLeaflets = useCallback(() => {
    setShowSearchResult(true);
    searchNationalResources(
      {
        entityType: NationalResourceEntityType.LEAFLET,
        query: searchInput,
        mode: "searchbyInput",
      },
      {
        onSuccess: (response) => {
          setSearchResults(response);
        },
      },
    );
  }, [searchInput, searchNationalResources]);

  useEffect(() => {
    if (searchInput === "") {
      setShowSearchResult(false);
    }
  }, [searchInput]);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle title={t("community_patientLeaflets_title")} />
        <NavButton onClick={() => history.goBack()} />
        <SearchSortList<EolasFile>
          value={searchInput}
          placeholderSearchText={t("findPatientLeaflets_searchPlaceholder")}
          items={showSearchResult ? searchResults : nationalBodies}
          isSearchable
          isLoading={searchingNationalResources || gettingNationalBodies}
          searchType="click"
          onSearchInputChange={setSearchInput}
          onClearSearch={handleClearSearch}
          onClickSearch={handleSearchLeaflets}
          renderItem={(eolasFile) => (
            <CommunityListItem
              eolasFile={eolasFile}
              onSelectFile={showSearchResult ? handleClickLeaflet : handleClickBody}
              sectionType={
                eolasFile.description || showSearchResult
                  ? CommunitySectionType.NATIONAL_RESOURCE
                  : CommunitySectionType.BODY
              }
            />
          )}
        />
      </InnerPageWrapper>
      <Modal open={!!modal} onClose={() => setModal(null)}>
        {modal}
      </Modal>
    </>
  );
});

export default PatientLeaflets;
