import {
  BlobContent,
  ContentItem,
  EolasEditorContent,
  FlashCardContent,
  isBlobContentItem,
  isEolasEditorContentItem,
  isFlashCardContentItem,
} from "@eolas-medical/core";
import useContentViewerContext from "./context/useContentViewerContext";
import FlashcardViewer from "./components/FlashcardViewer/FlashcardViewer";
import BlobViewer from "./components/BlobViewer/BlobViewer";
import { Loader } from "UIKit";
import { motion } from "framer-motion";
import { EolasEditorGenericViewer } from "Pages/FileViewer/components/EolasEditorViewer/EolasEditorViewer";
import { useRunOnMountUnmount } from "Hooks";
import useContentRepositoryContext from "../ContentRepository/context/useContentRepositoryContext";

const ContentViewer = () => {
  const { useContentItemHook } = useContentViewerContext();
  const { onLoadContentItem, contentItemConfig, ...restContextRepositoryContext } =
    useContentRepositoryContext();
  const { contentItem, isContentItemLoading } = useContentItemHook;

  const isSponsoredSlotItem =
    restContextRepositoryContext.contextDomain === "knowledge" &&
    (restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlots" ||
      restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlotsContent");

  const handleOnMountOfViewers = (contentItem: ContentItem) => {
    if (onLoadContentItem) {
      onLoadContentItem(contentItem);
    }
  };

  if (isContentItemLoading) {
    return (
      <div className="h-100vh flex justify-center items-center">
        <Loader className="bg-transparent" />
      </div>
    );
  }

  if (!contentItem) {
    return null;
  }

  if (isBlobContentItem(contentItem)) {
    return (
      <BlobViewerWrapper
        contentItem={contentItem}
        onMount={() => handleOnMountOfViewers(contentItem)}
      />
    );
  }

  if (isFlashCardContentItem(contentItem)) {
    return (
      <FlashcardViewerWrapper
        contentItem={contentItem}
        onMount={() => handleOnMountOfViewers(contentItem)}
        shouldShowDate={!isSponsoredSlotItem}
      />
    );
  }

  if (isEolasEditorContentItem(contentItem)) {
    return (
      <EolasEditorViewerWrapper
        shouldShowBreadcrumbs={contentItemConfig?.shouldShowBreadcrumbs}
        contentItem={contentItem}
        onMount={() => handleOnMountOfViewers(contentItem)}
      />
    );
  }
};

function BlobViewerWrapper({
  contentItem,
  onMount,
}: {
  contentItem: BlobContent;
  onMount: () => void;
}) {
  useRunOnMountUnmount({ onMount });
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { y: 50 },
        visible: { y: 0 },
      }}
      transition={{ duration: 0.1 }}
    >
      <BlobViewer blob={contentItem} />
    </motion.div>
  );
}

function FlashcardViewerWrapper({
  contentItem,
  onMount,
  shouldShowDate = true,
}: {
  contentItem: FlashCardContent;
  shouldShowDate?: boolean;
  onMount: () => void;
}) {
  useRunOnMountUnmount({ onMount });
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { y: -50 },
        visible: { y: 0 },
      }}
      transition={{ duration: 0.3 }}
    >
      <FlashcardViewer flashcard={contentItem} shouldShowDate={shouldShowDate} />
    </motion.div>
  );
}

function EolasEditorViewerWrapper({
  contentItem,
  onMount,
  shouldShowBreadcrumbs,
}: {
  contentItem: EolasEditorContent;
  onMount: () => void;
  shouldShowBreadcrumbs?: boolean;
}) {
  useRunOnMountUnmount({ onMount });
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { y: -50 },
        visible: { y: 0 },
      }}
      transition={{ duration: 0.3 }}
    >
      <EolasEditorGenericViewer
        contentItem={contentItem}
        contentItemLoading={false}
        fileIdOfDraftFile={null}
        shadowCopyContentId={null}
        shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      />
    </motion.div>
  );
}

export default ContentViewer;
