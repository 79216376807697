import { useLaunchDarkly } from "Contexts";
import { isBuildTokenPresent } from "Utilities/helpers";
import { LDFlagNames } from "Utilities/types";

export const useCheckAuthFlowBehaviour = (): "email-based" | "ui-direction-based" => {
  const { flags } = useLaunchDarkly();
  return flags[LDFlagNames.SHOULD_SKIP_ACCOUNT_CHECK] || isBuildTokenPresent()
    ? "email-based"
    : "ui-direction-based";
};
