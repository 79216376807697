import {
  AnalyticsEvents,
  ClinicalQuestionGeneralPayload,
  clinicalQuestionsClient,
  clinicalQuestionStore,
  CreateClinicalQuestionParams,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

const createClinicalQuestionAPI = async ({ text, notes, region }: CreateClinicalQuestionParams) => {
  const response = await clinicalQuestionsClient.createClinicalQuestion({
    text,
    notes,
    userID: userStore.userID,
    region,
  });
  return response;
};

export const useCreateClinicalQuestion = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const { mutateAsync, data } = useMutation({
    mutationFn: createClinicalQuestionAPI,
    mutationKey: ["createClinicalQuestion"],
    onSuccess: (response, args) => {
      clinicalQuestionStore.updateResponsesMap(response.requestID, {
        originalString: args.text,
        requestID: response.requestID,
        jobStatus: "PENDING",
        body: "",
      });
      if (args.text) {
        trackEvent<ClinicalQuestionGeneralPayload>(AnalyticsEvents.CLINICAL_QUESTION_ASKED, {
          query: args.text,
          questionID: response.requestID,
        });
      }

      if (args.notes) {
        trackEvent<ClinicalQuestionGeneralPayload>(
          AnalyticsEvents.CLINICAL_QUESTION_FOLLOW_UP_ASKED,
          {
            query: `ORIGINAL QUESTION: ${args.text} | FOLLOW UP: ${args.notes}`,
          },
        );
        clinicalQuestionStore.setFollowUpString(args.notes);
      }
    },
    onError: (error, args) => {
      eolasLogger.error(new Error("Error creating clinical question"), {
        error,
        question: args.text,
      });
      showNotification({
        type: "error",
        description: t("create_question_error"),
        autoHideTimeout: 5000,
      });
    },
  });

  return { createClinicalQuestion: mutateAsync, data };
};
