import {
  AnalyticsEvents,
  ClinicalQuestionResponsePayload,
  ClinicalQuestionStatusUpdateResponse,
  clinicalQuestionStore,
  clinicalQuestionsClient,
  eolasLogger,
} from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { trackEvent } from "API/Analytics";
import { useNotifications } from "Components/Notifications";
import { useRunOnMountUnmount } from "Hooks";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Timeout } from "Utilities/helpers";

type Args = {
  requestID: string;
  averageExecutionTimeInSeconds?: number;
};

export const makeGetClinicalQuestionResponseQueryKey = (requestID: string) => [
  "getClinicalQuestionResponse",
  requestID,
];

export const useGetClinicalQuestionResponse = ({
  requestID,
  averageExecutionTimeInSeconds,
}: Args) => {
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<Timeout | null>(null);
  const refetchCountRef = useRef(0);
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  useRunOnMountUnmount({
    onUnmount: () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    },
  });

  const startProgressGenerator = () => {
    const totalTimeInMilliseconds = averageExecutionTimeInSeconds
      ? averageExecutionTimeInSeconds * 1000
      : 15000;
    intervalRef.current = setInterval(() => {
      setProgress((previous) => {
        if (progress >= 100 && intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        return previous + 10;
      });
    }, totalTimeInMilliseconds / 10);
  };

  const queryFn = async (): Promise<ClinicalQuestionStatusUpdateResponse> => {
    const updatedResponse = await clinicalQuestionsClient.getClinicalQuestionStatus({
      requestID,
    });

    return updatedResponse;
  };

  const onSuccess = (updatedResponse: ClinicalQuestionStatusUpdateResponse) => {
    refetchCountRef.current = 0;
    clinicalQuestionStore.updateResponsesMap(updatedResponse.requestID, updatedResponse);
    if (
      !intervalRef.current &&
      clinicalQuestionStore.getIsResponseLoading(updatedResponse.requestID)
    ) {
      startProgressGenerator();
    }
    if (progress >= 100) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } else if (updatedResponse.progress && updatedResponse.progress > progress) {
      setProgress(updatedResponse.progress);
    }
    if (updatedResponse.jobStatus === "STREAMING") {
      setProgress(100);
    }

    if (["FINISHED", "FAILED"].includes(updatedResponse.jobStatus)) {
      setProgress(0);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (updatedResponse.statusEnum) {
        const originalQuestion =
          clinicalQuestionStore.responsesMap[updatedResponse.requestID]?.originalString ?? "";
        const { followUpQuestion } = clinicalQuestionStore;
        const params = {
          query: `ORIGINAL QUESTION: ${originalQuestion}${
            followUpQuestion ? `| FOLLOW UP QUESTION: ${followUpQuestion}` : ""
          }`,
          questionID: updatedResponse.requestID,
          statusEnum: updatedResponse.statusEnum,
        };
        trackEvent<ClinicalQuestionResponsePayload>(
          AnalyticsEvents.CLINICAL_QUESTION_RESPONSE_RECEIVED,
          params,
        );
      }

      if (updatedResponse.jobStatus === "FAILED") {
        throw new Error("Error getting response to clinical question");
      }
    }
  };

  const onError = (error: Error) => {
    refetchCountRef.current += 1;
    if (refetchCountRef.current > 3) {
      eolasLogger.error(new Error("Failed 3 retries"), { error });
      showNotification({
        type: "error",
        description: t("clinical_question_response_error"),
        autoHideTimeout: 5000,
      });
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const { isSuccess } = useQuery({
    queryKey: makeGetClinicalQuestionResponseQueryKey(requestID),
    queryFn,
    enabled: Boolean(clinicalQuestionStore.getIsJobActive(requestID)),
    onSuccess,
    retry: false,
    onError,
    refetchInterval: () => {
      // Disable refetch after 3 consecutive errors
      if (refetchCountRef.current > 3) {
        return false;
      }
      return 1000;
    },
  });

  return {
    progress,
    isSuccess,
  };
};
