import { useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { userStore, sectionStore, eolasLogger, AnalyticsEvents } from "@eolas-medical/core";
import { NotificationsProvider } from "Components/Notifications";
import { LDProvider } from "./LaunchDarklyContext";
import { globalWaitForHydration } from "Utilities/appStartup";
import MasterSearchProvider from "Components/MasterSearch/context/MasterSearchProvider";
import { PushNotificationsProvider } from "services/pushNotifications";
import { SpacesProvider } from "modules/spaces/context/SpacesProvider";
import { SessionProvider } from "modules/session";
import { DocumentCacheProvider } from "services/documentCache";
import { Sentry, queryClient } from "config";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { trackEvent } from "API/Analytics";
import { useRunOnMountUnmount } from "Hooks";
import { migrateFromFileStore } from "Utilities/migrations/migrateFromFileStore";
import { deleteLegacyMedusaFiles } from "Utilities/migrations/deleteLegacyMedusaFiles";

export const EolasProviders = ({ children }: { children: React.ReactNode }) => {
  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useRunOnMountUnmount({
    onMount: async () => {
      try {
        await globalWaitForHydration();
        const userData = userStore.userData;
        if (userData.id && userData.email) {
          Sentry.setUser({
            id: userData.id,
            appUserID: userStore.appUserID,
            appID: sectionStore.appID,
          });
        }
        await migrateFromFileStore();
        if (userStore.userSession.isInOrganisation) {
          await localSearchStore.initialiseDb({ isNew: false });
          await deleteLegacyMedusaFiles();
        }
      } catch (error) {
        eolasLogger.error(new Error("Error initialising app"), { error });
      } finally {
        trackEvent(AnalyticsEvents.EOLAS_SESSION_START);
        setIsHydrated(true);
      }
    },
  });

  if (!isHydrated) {
    return <div />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DocumentCacheProvider>
        <LDProvider>
          <SessionProvider>
            <SpacesProvider>
              <PushNotificationsProvider>
                <NotificationsProvider>
                  <MasterSearchProvider>{children}</MasterSearchProvider>
                </NotificationsProvider>
              </PushNotificationsProvider>
            </SpacesProvider>
          </SessionProvider>
        </LDProvider>
      </DocumentCacheProvider>
    </QueryClientProvider>
  );
};
