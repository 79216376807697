import React, { useMemo, useEffect } from "react";
import { Loader } from "UIKit";
import { useTranslation } from "react-i18next";
import LZString from "lz-string";
import {
  AnalyticsEvents,
  ContentItem,
  objectIsEolasEditorMetadata,
  sectionStore,
  fromAwsJSON,
} from "@eolas-medical/core";
import { transformLinksToOpenInNewTabs } from "Pages/FileViewer/functions/helpers";
import { trackEvent } from "API/Analytics";
import { isBuildTokenPresent } from "Utilities/helpers";

interface EolasEditorRendererProps {
  contentItem: ContentItem | undefined;
  contentItemLoading: boolean;
}

export const EolasEditorRenderer: React.FC<EolasEditorRendererProps> = ({
  contentItem,
  contentItemLoading,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!contentItem) return;

    const mainSectionType = contentItem.mainSectionId
      ? sectionStore.getMainSectionTypeFromMainSectionID(contentItem.mainSectionId)
      : null;

    const mainSectionIdentity = contentItem.mainSectionId
      ? sectionStore.getMainSectionIdentityByMainSectionId(contentItem.mainSectionId)
      : null;

    // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
    const idForTracking = mainSectionType ?? mainSectionIdentity;

    if (idForTracking) {
      trackEvent(AnalyticsEvents.OPEN_FILE, {
        mainSectionId: idForTracking,
        fileId: contentItem.id,
        fileType: contentItem.type || "",
        fileName: contentItem.name,
      });
    }
  }, [contentItem]);

  const styledContent = useMemo(() => {
    if (contentItemLoading || !contentItem?.metadata) {
      return null;
    }

    const metadata = fromAwsJSON(contentItem.metadata);
    if (!objectIsEolasEditorMetadata(metadata)) {
      return null;
    }

    const decodedContent = LZString.decompressFromEncodedURIComponent(metadata.eolasEditorContent);
    if (!decodedContent) {
      return null;
    }

    const modifiedContent = transformLinksToOpenInNewTabs(decodedContent);
    const overrideCss =
      "div, ul, li, table, td { width: auto !important; overflow: visible !important; }";
    const overrideCssForImages = "img { overflow: visible !important; }";

    const responsiveStyle = `
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
        html, body {
          height: 100%;
          margin: 0;
          padding: 0;
          font-family: 'Nunito', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
        body {
          overflow: auto;
          padding: 0.5rem;
        }
        ${overrideCss}
        ${overrideCssForImages}
      </style>
    `;

    return responsiveStyle + modifiedContent;
  }, [contentItem, contentItemLoading]);

  if (contentItemLoading) {
    return <Loader className="relative top-32 bg-transparent" />;
  }

  if (!styledContent) {
    return <div>{t("error_loading_content")}</div>;
  }

  return (
    <div className="rounded-lg p-4 bg-white" style={{ marginTop: 10 }}>
      <iframe
        srcDoc={styledContent}
        className="w-full flex flex-grow border-0 bg-white h-60vh lg:h-70vh"
        sandbox={`${
          isBuildTokenPresent() ? "" : "allow-same-origin "
        }allow-scripts allow-popups allow-popups-to-escape-sandbox allow-downloads`}
      />
    </div>
  );
};
