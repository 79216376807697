import { EolasFile, hasStringProp } from "@eolas-medical/core";
import {
  EOLAS_BODY_WEIGHT_CALC_NAME,
  EOLAS_CR_CL_CALC_NAME,
  EOLAS_GENT_CALC_NAME,
  EOLAS_NATIONAL_BODY,
} from "../types";

export const getNationalBodyFromMetadata = (metadata?: string | null): string | null => {
  if (!metadata) {
    return null;
  }

  try {
    const parsedMetadata = JSON.parse(metadata);
    if (hasStringProp(parsedMetadata, "nationalBody")) {
      return parsedMetadata.nationalBody;
    }
    return null;
  } catch {
    return null;
  }
};

export const isEolasGentCalc = (file: EolasFile): boolean => {
  const nationalBody = getNationalBodyFromMetadata(file.metadata);

  if (nationalBody !== EOLAS_NATIONAL_BODY) {
    return false;
  }

  return file.name === EOLAS_GENT_CALC_NAME;
};

export const isEolasBodyWeightCalc = (file: EolasFile): boolean => {
  const nationalBody = getNationalBodyFromMetadata(file.metadata);

  if (nationalBody !== EOLAS_NATIONAL_BODY) {
    return false;
  }

  return file.name === EOLAS_BODY_WEIGHT_CALC_NAME;
};

export const isEolasCrClCalc = (file: EolasFile): boolean => {
  const nationalBody = getNationalBodyFromMetadata(file.metadata);

  if (nationalBody !== EOLAS_NATIONAL_BODY) {
    return false;
  }

  return file.name === EOLAS_CR_CL_CALC_NAME;
};

export const removeDisabledEolasCalc = (
  file: EolasFile,
  disabledCalcs: {
    isEolasGentCalcDisabled: boolean;
    isEolasBodyWeightCalcDisabled: boolean;
    isEolasCrClCalcDisabled: boolean;
  },
): boolean => {
  if (disabledCalcs.isEolasGentCalcDisabled && isEolasGentCalc(file)) {
    return false;
  }

  if (disabledCalcs.isEolasBodyWeightCalcDisabled && isEolasBodyWeightCalc(file)) {
    return false;
  }

  if (disabledCalcs.isEolasCrClCalcDisabled && isEolasCrClCalc(file)) {
    return false;
  }

  return true;
};
