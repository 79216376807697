import { AnalyticsEvents, ClinicalQuestionReferencePayload } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { makeProcessNode, makeShouldProcessNode } from "shared/functions/makeHtmlToReactNodeParser";

export const getResponseProcessingInstructions = (questionID: string) => [
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "ul" }),
    processNode: makeProcessNode({ tagName: "ul", classNameToAdd: "list-disc px-8 text-base" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "p" }),
    processNode: makeProcessNode({ tagName: "p", classNameToAdd: "text-base my-2 my-0.5" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "li" }),
    processNode: makeProcessNode({ tagName: "li", classNameToAdd: "text-base my-0.5" }),
  },
  {
    shouldProcessNode: makeShouldProcessNode({ tagName: "a" }),
    processNode: makeProcessNode({
      tagName: "a",
      classNameToAdd: "text-base text-blue-500 hover:text-blue-700 underline",
      attributesToAdd: ["id"],
      onAnchorClick: (event) => {
        if (
          event.currentTarget.href.includes("clinicalQuestions") &&
          event.currentTarget.href.includes("ref-")
        ) {
          trackEvent<ClinicalQuestionReferencePayload>(
            AnalyticsEvents.CLINICAL_QUESTION_JUMP_LINK_CLICKED,
            { questionID },
          );
        } else {
          trackEvent<ClinicalQuestionReferencePayload>(
            AnalyticsEvents.CLINICAL_QUESTION_REFERENCE_CLICKED,
            {
              questionID,
              url: event.currentTarget.href,
            },
          );
        }
      },
    }),
  },
];
