import {
  AnalyticsEvents,
  DeeplinkTappedPayload,
  OrganisationLevelSection,
  eolasLogger,
  userStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { useMediaQuery } from "Hooks";
import { useAutoLogin } from "Pages/Authentication/pages/AutoLogin/useAutologin";
import { expectNever } from "Utilities/helpers";
import { LDFlagNames } from "Utilities/types";
import {
  extractCommsPortalParams,
  extractLoginToken,
  getDeeplinkEntityId,
  getDeeplinkEntityType,
  getDeeplinkMainSectionIdentity,
  redirectToLinkedDocument,
} from "deeplinking/functions/helpers";
import { isSupportedOrganisationContentDeepLinkType } from "deeplinking/functions/typeguards";
import { DeepLinkParams, DeeplinkError } from "deeplinking/types/general.types";
import { useSpacesContext } from "modules/spaces";
import React, { useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

export const useHandleOrganisationDeeplink = () => {
  const didFireAnalyticsEvent = React.useRef(false);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { autoLogin, isLoading: isLoggingInWithToken } = useAutoLogin({
    callbackUrl: pathname + search,
  });
  const { selectedSpace, isInSpace } = useSpacesContext();
  const isInitialLoadingSpaceData = isInSpace && !selectedSpace;
  const media = useMediaQuery();
  const { type } = useParams<DeepLinkParams>();
  const { flags } = useLaunchDarkly();
  const { toggleDrawerHidden } = useEolasNavigation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [deeplinkError, setDeeplinkError] = React.useState<DeeplinkError | null>(null);
  const [shouldDisplayDirectToMobile, setShouldDisplayDirectToMobile] =
    React.useState<boolean>(false);
  const isMobile = media === "xs";
  const shouldDirectToMobileFlag =
    flags[LDFlagNames.SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE] ?? false;

  const setDisplayDirectToMobile = React.useCallback(() => {
    setShouldDisplayDirectToMobile(true);
    toggleDrawerHidden(true);
  }, [toggleDrawerHidden]);

  useEffect(() => {
    if (isInitialLoadingSpaceData || isLoggingInWithToken) {
      // If this is true, the spaces context is still loading and we should wait for it to finish before handling the deeplink
      return;
    }

    if (!userStore.userSession.isLoggedIn) {
      const maybeLoginToken = extractLoginToken(search);
      if (maybeLoginToken) {
        autoLogin(maybeLoginToken);
        return;
      }
    }

    if (type && isSupportedOrganisationContentDeepLinkType(type)) {
      if (didFireAnalyticsEvent.current === false) {
        didFireAnalyticsEvent.current = true;
        trackEvent<DeeplinkTappedPayload>(AnalyticsEvents.DEEPLINK_TAPPED, {
          entityId: getDeeplinkEntityId(search, type),
          type: getDeeplinkEntityType(search, type),
          mainSectionType: getDeeplinkMainSectionIdentity(type),
        });
      }
      switch (type) {
        case OrganisationLevelSection.communicationPortal: {
          const commsPortalParams = extractCommsPortalParams(search, type);
          if (commsPortalParams) {
            setIsLoading(false);
            if (isMobile && shouldDirectToMobileFlag) {
              setDisplayDirectToMobile();
            } else {
              redirectToLinkedDocument({
                basePath: "/organisation",
                legacyType: type,
                history,
                searchParams: search,
              });
            }
          } else {
            setIsLoading(false);
            setDeeplinkError("invalidParams");
            eolasLogger.error(
              new Error("Communications portal deeplink is missing or has invalid params"),
              {
                search,
              },
            );
          }
          break;
        }
        // Content repositories
        case "null":
        case OrganisationLevelSection.hospitalGuidelines:
        case OrganisationLevelSection.drugMonitoring:
        case OrganisationLevelSection.genericOrganisationGuidelines:
        case OrganisationLevelSection.organisationWellbeing:
        case OrganisationLevelSection.medusaMedications:
        case OrganisationLevelSection.learningManagement:
        case OrganisationLevelSection.hospitalContacts:
        case OrganisationLevelSection.orgPatientLeaflets:
          setIsLoading(false);
          if (isMobile && shouldDirectToMobileFlag) {
            setDisplayDirectToMobile();
          } else {
            redirectToLinkedDocument({
              basePath: "/organisation",
              legacyType: type,
              history,
              searchParams: search,
            });
          }
          break;
        default: {
          // If the below line throws a type error, it means that the type is not handled in the switch statement above
          expectNever(type);
        }
      }
    } else {
      setIsLoading(false);
      setDeeplinkError("unSupportedType");
    }

    return () => {
      toggleDrawerHidden(false);
    };
  }, [
    search,
    type,
    history,
    isMobile,
    setDisplayDirectToMobile,
    toggleDrawerHidden,
    shouldDirectToMobileFlag,
    autoLogin,
    isInitialLoadingSpaceData,
    isLoggingInWithToken,
  ]);

  return {
    isLoading,
    hasError: !!deeplinkError,
    error: deeplinkError,
    shouldDisplayDirectToMobile: shouldDisplayDirectToMobile,
    isMobileBrowser: isMobile,
    type,
    searchString: search,
  };
};
