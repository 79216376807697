import envConfig from "env-config";
import { useMutation } from "@tanstack/react-query";
import { modalStore } from "Stores/ModalStore";
import { useTranslation } from "react-i18next";
import { sendZapierRequest } from "shared/functions/sendZapierRequest";
import { LoginCredentials } from "Pages/Authentication/signup/types";
import { useState } from "react";

const useAssistanceVerificationCode = ({
  email,
  firstName,
  lastName,
  onSuccess,
}: Omit<LoginCredentials, "password"> & {
  onSuccess?: () => void;
  origin: "SIGN_UP" | "SIGN_IN" | "FORGOT_PASSWORD";
}) => {
  const { t } = useTranslation();
  const [buttonPressed, setButtonPressed] = useState<"sendRequest" | "cancel">("cancel");

  const { mutateAsync, reset, isLoading, error } = useMutation({
    mutationKey: ["requestAssistanceVerificationCode"],
    mutationFn: () =>
      sendZapierRequest({
        type: "GET",
        baseUrl:
          envConfig.REACT_APP_ZAPIER_HOOK_URL + envConfig.REACT_APP_ZAPIER_CATCH_VERIFICATION_CODE,
        queryParams: { email, firstName: firstName || "", lastName: lastName || "", origin },
      }),
  });

  const onRequestAssistance = () => {
    modalStore.openModal({
      variant: "dialogue",
      layoutType: "stacked",
      icon: "warning",
      title: t("no_email_received"),
      message: t("no_email_received_description", { email }),
      name: "verifyAssistanceModal",
      onConfirmAsync: async () => {
        setButtonPressed("sendRequest");
        await mutateAsync();
      },
      onDismiss: () => {
        setButtonPressed("cancel");
      },
      confirmText: t("email_correct"),
      dismissText: t("general_cancel"),
      onModalClosed: () => {
        reset();
        if (buttonPressed === "cancel") {
          return;
        }
        if (onSuccess) {
          onSuccess();
          return;
        }
        modalStore.openModal({
          variant: "dialogue",
          layoutType: "stacked",
          title: t("request_assistance_title"),
          message: t("request_assistance_description"),
          name: "verifyAssistanceConfirmModal",
          dialogType: "confirmOnly",
          confirmText: t("general_ok"),
        });
      },
    });
  };

  return {
    requestAssistanceVerificationCode: onRequestAssistance,
    isRequestingAssistance: isLoading,
    error,
  };
};

export default useAssistanceVerificationCode;
