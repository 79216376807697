import WebViewer, { Core, UI } from "@pdftron/webviewer";
import { useTranslation } from "react-i18next";
import {
  AnalyticsEvents,
  DraftFile,
  EolasFile,
  contentClient,
  eolasLogger,
  sectionStore,
} from "@eolas-medical/core";
import { useState, useRef, useMemo } from "react";

import { trackEvent } from "API/Analytics";
import { getPDFUrl } from "Hooks/useDocumentCache";
import { useNotifications, Notification } from "Components/Notifications";
import { isUrlString } from "Utilities";
import { debounce } from "lodash";
import { useShareFile } from "shared/hooks/useShareFile";
import ShareFileModal from "Pages/FileViewer/components/DocumentViewer/components/ShareFileModal";
import { getFileName } from "Utilities/fileHelpers";
import { modalStore } from "Stores/ModalStore";
import { InsertLinkModal } from "Pages/FileViewer/components/DocumentViewer/components/InsertLinkModal/InsertLinkModal";
import { useGetAdminStatus } from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEolasNavigation } from "Components/Navigation/hooks";
import {
  isAncestorSectionIdInLimitedAccessArray,
  makeLimitedAdminSectionLookupMap,
} from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/functions/filterLimitedSections";
import { useGetLimitedAccess } from "Pages/Spaces/pages/Space/pages/ManageUsers/components/ManageAdmin/LimitedAdmin/hooks/useGetLimitedAccess";
import { useUploadBlob } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useUploadBlob";
import { isEolasDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/typeguards";
import { useRefetchAppData, useRunOnMountUnmount } from "Hooks";
import { intermediateUpdateForUi } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/functions/intermediateUpdateForUi";
import { PDF_TRON_SOURCE } from "config/pdftron.constants";

interface LinkAnnotation extends Core.Annotations.Link {
  PageNumber: number;
  StrokeColor: Core.Annotations.Color;
  StrokeStyle: string;
  StrokeThickness: number;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  addAction(event: string, action: Core.Actions.Action): void;
}

type TextPopUpButton = {
  type: "actionButton";
  onClick: () => void;
  img?: string;
  label?: string;
};

type HeaderButton = {
  type: "actionButton";
  onClick: () => void;
  img: string;
};

type Quad = {
  x1: number;
  x3: number;
  y1: number;
  y3: number;
};

/**
 * This is a custom type for the TextPopup object from the WebViewer API.
 * The WebViewer API provides a very loose typing for this object, so we need to define it ourselves.
 */
type TextPopup = {
  add: (items: TextPopUpButton[]) => void;
} & Omit<UI.Popup, "add">;

/**
 * This is a custom type for the Header object from the WebViewer API.
 * The WebViewer API provides a very loose typing for this object, so we need to define it ourselves.
 */
type Header = {
  push: (items: HeaderButton) => void;
} & Omit<UI.Header, "push">;

export const useFileViewer = ({
  document,
  startingPage,
  versionNo,
  handleGoBack,
}: {
  handleGoBack: () => void;
  document: EolasFile | DraftFile;
  startingPage?: number;
  versionNo?: number;
}) => {
  const { key: fileKey, type, parentID } = document;
  const key = isUrlString(fileKey || "") ? fileKey : decodeURIComponent(fileKey ?? "");

  const { t } = useTranslation();
  const notificationRef = useRef<Notification | null>();
  const addLinkToSelectedTextRef = useRef<(linkUrl: string) => void>();
  const viewer = useRef<HTMLDivElement | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const latestBlob = useRef<null | File>(null);

  const { uploadBlob } = useUploadBlob();

  const { refetch } = useRefetchAppData();

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const { isShareFileEnabled, isShareModalOpen, closeShareModal, handleOnShareFile, shareModal } =
    useShareFile({ Modal: ShareFileModal });

  const { activeTab } = useEolasNavigation();
  const { isAdmin: isInAdminMode } = sectionStore;

  const adminStatus = useGetAdminStatus({ activeTab });

  const limitedAccess = useGetLimitedAccess({ activeTab });

  const shouldDisplayAdminOptions = useMemo(() => {
    if (!adminStatus || !isInAdminMode) {
      return false;
    }
    if (adminStatus === "admin") {
      return true;
    }

    const lookupMap = makeLimitedAdminSectionLookupMap(limitedAccess);
    return isAncestorSectionIdInLimitedAccessArray(parentID, lookupMap);
  }, [adminStatus, limitedAccess, parentID, isInAdminMode]);

  const saveLatestMedia = async () => {
    if (!latestBlob.current) {
      return;
    }
    try {
      const mediaName = getFileName(latestBlob.current);

      const result = await uploadBlob({
        mainSectionId: document.mainSectionID,
        blob: latestBlob.current,
        mediaName,
      });

      if (isEolasDraftFile(document)) {
        await contentClient.updateDraftFile({
          draftFileDto: {
            key: result.type === "public" ? result.url : result.s3Key,
            mediaId: !result.isLegacy && result.type === "private" ? result.s3Key : undefined,
            mediaName,
          },
          contentId: document.fileId,
          mainSectionId: document.mainSectionID,
        });
      } else {
        const contentFile = await contentClient.updateContentItem({
          contentDto: {
            key: result.type === "public" ? result.url : result.s3Key,
            mediaId: !result.isLegacy && result.type === "private" ? result.s3Key : undefined,
            mediaName,
          },
          contentId: document.id,
          mainSectionId: document.mainSectionID,
        });
        intermediateUpdateForUi({ type: "file", file: contentFile, action: "update" });
      }
      refetch();
    } catch (error) {
      eolasLogger.error(error);
    }
    latestBlob.current = null;
  };

  const handleSaveBlob = (blob: Blob) => {
    const fileBlob = new File([blob], `${document.name}.pdf`, {
      lastModified: new Date().getTime(),
      type: "application/pdf",
    });

    latestBlob.current = fileBlob;
  };

  const onClickBack = () => {
    if (!latestBlob.current) {
      handleGoBack();
      return;
    } else {
      modalStore.openModal({
        icon: "warning",
        name: "confirmSaveChanges",
        variant: "dialogue",
        title: t("document_save_changes_title"),
        message: t("document_save_changes_desc"),
        onConfirmAsync: async () => {
          await saveLatestMedia();
          handleGoBack();
        },
        onDismiss: () => {
          latestBlob.current = null;
          handleGoBack();
        },
      });
    }
  };

  const onPDFError = (e: unknown) => {
    if (!notificationRef.current) {
      return;
    }
    updateNotification({
      type: "error",
      id: notificationRef.current.id,
      description: t("fileViewer_error"),
    });
    if (e) {
      eolasLogger.error(e);
    }
  };

  const onPDFLoaded = () => {
    if (!notificationRef.current) {
      return;
    }
    hideNotification(notificationRef.current.id);
    notificationRef.current = null;
  };

  const onViewerLoadError = () => {
    if (!notificationRef.current) {
      return;
    }
    updateNotification({
      type: "error",
      id: notificationRef.current.id,
      description: t("fileViewer_load_error"),
    });
  };

  const getAllPdfUrls = async (fileKey: string) => {
    if (isUrlString(fileKey)) {
      return fileKey;
    }

    let fileId: string;
    let draftId: string | undefined = undefined;

    if (isEolasDraftFile(document)) {
      fileId = document.fileId;
      draftId = document.id;
    } else {
      fileId = document.id;
    }
    return getPDFUrl({ fileId, key: fileKey, versionNo, draftId });
  };

  const handleLinkSubmit = (link: string) => {
    if (addLinkToSelectedTextRef.current) {
      addLinkToSelectedTextRef.current(link);
    }
    modalStore.closeModal();
  };

  const initialiseViewer = async ({ pdfUrl }: { pdfUrl: string }) => {
    if (!viewer.current) {
      return;
    }
    const { UI, Core } = await WebViewer(
      {
        path: PDF_TRON_SOURCE,
        licenseKey: process.env.REACT_APP_PDF_TRON,
        initialDoc: pdfUrl,
        disabledElements: [
          "ribbons",
          "toolsHeader",
          "toggleNotesButton",
          "selectToolButton",
          "textHighlightToolButton",
          "textUnderlineToolButton",
          "textSquigglyToolButton",
          "textStrikeoutToolButton",
          "linkButton",
        ],
      },
      viewer.current,
    );

    const { documentViewer, annotationManager, Annotations, Actions } = Core;

    const saveChanges = async () => {
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({ xfdfString });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });

      handleSaveBlob(blob);
    };

    const saveDocumentWithDebounce = debounce(async () => {
      saveChanges();
    }, 3000);

    const newLink = (
      x: number,
      y: number,
      width: number,
      height: number,
      linkPageNumber: number,
    ): LinkAnnotation => {
      const link: Partial<LinkAnnotation> = new Annotations.Link({});
      link.PageNumber = linkPageNumber;
      link.StrokeColor = new Annotations.Color(0, 165, 228);
      link.StrokeStyle = "underline";
      link.StrokeThickness = 2;
      link.X = x;
      link.Y = y;
      link.Width = width;
      link.Height = height;

      // FIXME: Docs on AppRyse docs being super unclear and how important this feature is, we are maintaining the cast for now
      return link as LinkAnnotation;
    };

    const addLinkToSelectedText = (linkUrl: string) => {
      if (!linkUrl) return; // Check if a link is selected

      const selectedTextQuads = documentViewer.getSelectedTextQuads();
      const currentPageLinks: Core.Annotations.Annotation[] = [];
      const action = new Actions.URI({ uri: linkUrl });

      for (const pageNumber in selectedTextQuads) {
        selectedTextQuads[pageNumber].forEach(
          (quad: { x1: number; x3: number; y1: number; y3: number }) => {
            const link = newLink(
              Math.min(quad.x1, quad.x3),
              Math.min(quad.y1, quad.y3),
              Math.abs(quad.x1 - quad.x3),
              Math.abs(quad.y1 - quad.y3),
              parseInt(pageNumber),
            );

            link.addAction("U", action);

            currentPageLinks.push(link);
          },
        );
      }
      annotationManager.addAnnotations(currentPageLinks);
      let pageNumbersToDraw = currentPageLinks.map((link) => link.PageNumber);
      pageNumbersToDraw = [...new Set(pageNumbersToDraw)];
      pageNumbersToDraw.forEach((pageNumberToDraw: number) => {
        annotationManager.drawAnnotations({
          pageNumber: pageNumberToDraw,
          overrideCanvas: null,
          majorRedraw: true,
        });
      });
      saveDocumentWithDebounce();
    };

    const deleteLinkFromSelectedText = async () => {
      const { documentViewer, annotationManager, Annotations } = Core;
      const selectedTextQuads = documentViewer.getSelectedTextQuads();
      let currentPageLinksToDelete: Core.Annotations.Annotation[] = [];

      Object.keys(selectedTextQuads).forEach((pageNumber) => {
        const quads = selectedTextQuads[pageNumber];
        quads.forEach((quad: Quad) => {
          const pageAnnotations = annotationManager.getAnnotationsList().filter((ann) => {
            // Ensure we're dealing with a link annotation on the correct page
            if (!(ann instanceof Annotations.Link) || ann.PageNumber !== parseInt(pageNumber, 10)) {
              return false;
            }

            // Quad boundaries (assuming quads are roughly rectangular and aligned)
            const quadMinX = Math.min(quad.x1, quad.x3);
            const quadMaxX = Math.max(quad.x1, quad.x3);
            const quadMinY = Math.min(quad.y1, quad.y3);
            const quadMaxY = Math.max(quad.y1, quad.y3);

            // Annotation boundaries
            const annMinX = ann.X;
            const annMaxX = ann.X + ann.Width;
            const annMinY = ann.Y;
            const annMaxY = ann.Y + ann.Height;

            // Check if the annotation rectangle intersects the quad rectangle
            return (
              quadMinX < annMaxX && quadMaxX > annMinX && quadMinY < annMaxY && quadMaxY > annMinY
            );
          });

          currentPageLinksToDelete = currentPageLinksToDelete.concat(pageAnnotations);
        });
      });

      if (currentPageLinksToDelete.length > 0) {
        annotationManager.deleteAnnotations(currentPageLinksToDelete);
        // Trigger any necessary updates or saves similar to what you do after adding an annotation
        await saveChanges();
      }
    };

    addLinkToSelectedTextRef.current = addLinkToSelectedText;

    // Note: make available for pdfs only, otherwise users could overwrite other document types as pdf
    // Disabled add links option for pdfs of old versions
    if (shouldDisplayAdminOptions && type === "pdf" && !versionNo) {
      const textPopUp: TextPopup = UI.textPopup;
      textPopUp.add([
        {
          type: "actionButton",
          img: "https://static.eolas.click/SectionImages/svgIcons/trashCan.svg",
          onClick: () => deleteLinkFromSelectedText(),
        },
        {
          type: "actionButton",
          label: "LINK",
          onClick: () =>
            modalStore.openModal({
              name: "selectLinkModal",
              variant: "component",
              Component: (
                <InsertLinkModal onInsertLink={handleLinkSubmit} disabledIds={[document.id]} />
              ),
            }),
        },
      ]);
    }

    if (isShareFileEnabled) {
      UI.setHeaderItems((header: Header) => {
        header.push({
          type: "actionButton",
          img: "https://prod-eolas-public-ui-content.s3.eu-west-1.amazonaws.com/Icons/share.svg",
          onClick: () =>
            handleOnShareFile(document, {
              totalPages: documentViewer.getPageCount(),
              pageNumber: documentViewer.getCurrentPage(),
            }),
        });
      });
    }

    UI.addEventListener(UI.Events.DOCUMENT_LOADED, () => {
      if (startingPage) {
        documentViewer.setCurrentPage(startingPage, false);
      }
      onPDFLoaded();
    });
    UI.addEventListener(UI.Events.LOAD_ERROR, onViewerLoadError);
    Core.disableEmbeddedJavaScript();
  };

  useRunOnMountUnmount({
    onMount: async () => {
      if (!key) {
        eolasLogger.error(
          new Error("useDocumentViewer: no key on eolas file. This should not happen"),
          { document },
        );
        return;
      }
      notificationRef.current = showNotification({
        type: "loading",
        description: t("fileViewer_opening_pdf"),
      });
      trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
      try {
        const url = await getAllPdfUrls(key);
        setPdfUrl(url);
        initialiseViewer({ pdfUrl: url });
        trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_SUCCESS);
      } catch (error) {
        onPDFError(error);
        trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_ERROR);
      }
    },
    onUnmount: () => {
      saveLatestMedia();
      viewer.current = null;
      if (!notificationRef.current?.id) {
        return;
      }
      hideNotification(notificationRef.current.id);
      notificationRef.current = null;
    },
  });

  return {
    viewer,
    pdfUrl,
    showNotification,
    onPDFLoaded,
    onPDFError,
    shareModal,
    isShareModalOpen,
    closeShareModal,
    onClickBack,
  };
};
