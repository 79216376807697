import {
  EolasFile,
  OrganisationLevelSection,
  SectionNonAppsync,
  eolasLogger,
  hasProp,
  isEolasFile,
  sectionStore,
} from "@eolas-medical/core";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { getItemOwnerType } from "Utilities/fileHelpers";
import { BaseAppRoute } from "Utilities/types";
import { mapToContentSection } from "modules/contentRepository/helpers";

export type ErrorCodes =
  | "ITEM_NOT_FOUND"
  | "INFINITE_LOOP_DETECTED"
  | "PARENT_CHILD_REFERENCE_NOT_FOUND"
  | "PARENT_SECTION_NOT_FOUND"
  | "ITEM_OWNER_TYPE_NOT_FOUND"
  | "ENCODED_SPACE_NAME_NOT_FOUND";

type Params = {
  /**
   * The id of the content item or section
   */
  id: string;
  isInAdminMode: boolean;
  encodedSelectedSpaceName: string | null;
};

export const getPathToItemOrSection = async ({
  id,
  isInAdminMode,
  encodedSelectedSpaceName,
}: Params): Promise<string | { error: ErrorCodes }> => {
  let path = "";
  let isParentMainSection = false;
  let count = 0;
  let error: ErrorCodes | null = null;

  let item: EolasFile | SectionNonAppsync | null = null;

  const maybeMedusaSection = sectionStore.medusaSectionsWithItems.find(
    (section) => section.id === id,
  );
  if (maybeMedusaSection) {
    return `/${BaseAppRoute.organisation}/${encodedSelectedSpaceName}/${OrganisationLevelSection.medusaMedications}/${maybeMedusaSection.id}`;
  }

  const fileItem = await contentDbStore.getItem(id);

  if (fileItem) {
    item = fileItem;
  } else {
    const sectionItem = sectionStore.getSection(id);
    if (sectionItem) {
      item = sectionItem;
    }
  }

  if (!item) {
    return { error: "ITEM_NOT_FOUND" };
  }

  const itemOwnerType = getItemOwnerType(item);

  if (!itemOwnerType) {
    return { error: "ITEM_OWNER_TYPE_NOT_FOUND" };
  }

  if (!encodedSelectedSpaceName) {
    eolasLogger.error(
      new Error("Encoded selected space name not found when generating path to item or section"),
      { item },
    );
    return { error: "ENCODED_SPACE_NAME_NOT_FOUND" };
  }

  let parentId = isEolasFile(item) && !hasProp(item, "childrenType") ? item.parentID : item.id;

  while (!isParentMainSection) {
    if (!parentId) {
      error = "PARENT_SECTION_NOT_FOUND";
      break;
    }

    if (count > 50) {
      error = "INFINITE_LOOP_DETECTED";
      break;
    }
    const childRef = sectionStore.getChildReferenceOfSection(parentId);
    const section = sectionStore.getSection(parentId);

    if (!childRef) {
      error = "PARENT_CHILD_REFERENCE_NOT_FOUND";
      break;
    }

    const contentSection = mapToContentSection(childRef);

    if (childRef.id === item.mainSectionID) {
      isParentMainSection = true;
      path = `${contentSection.routeId}/${path}`;
    } else {
      path = `${contentSection.routeId}/${path}`;
      parentId = section.parentID || "";
    }
    count++;
  }

  if (error) {
    return { error };
  }

  let basePath =
    itemOwnerType === "spaces"
      ? `/${BaseAppRoute.spaces}/${encodedSelectedSpaceName}`
      : `/${BaseAppRoute.organisation}/${encodedSelectedSpaceName}`;

  const section = sectionStore.getSection(id);

  if (isInAdminMode) {
    basePath = `${basePath}/content-management`;
  }

  let fullPath = "";

  if (section.identity === "genericContentRepository") {
    fullPath = `${basePath}/content-repository/${path}`;
  } else {
    fullPath = `${basePath}/${section.mainSectionType}${
      section.mainSectionID !== section.id ? `/${encodeURIComponent(section.id)}` : ""
    }`;
  }

  if (fullPath.endsWith("/")) {
    const sanitizedPath = fullPath.slice(0, -1);
    return sanitizedPath;
  } else {
    return fullPath;
  }
};
