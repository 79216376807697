import WebViewer from "@pdftron/webviewer";
import { initializeVideoViewer } from "@pdftron/webviewer-video";
import { useTranslation } from "react-i18next";
import { AnalyticsEvents, hasStringProp } from "@eolas-medical/core";
import { useEffect, useState, useRef, useCallback } from "react";

import { trackEvent } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";
import { useNotifications, Notification } from "Components/Notifications";
import { BlobTypes } from "modules/generic.types";
import { isVideoAutoPlayError } from "../functions/helpers";
import { PDF_TRON_SOURCE } from "config/pdftron.constants";

interface BasicFile {
  id: string;
  name: string;
  type: BlobTypes;
  url: string;
}

const useFileViewer = <T extends BasicFile>() => {
  const { t } = useTranslation();
  const notificationRef = useRef<Notification>();
  const viewer = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<T | null>(null);

  const { showNotification, hideNotification, updateNotification } = useNotifications();

  const onFileError = useCallback(
    (e: unknown) => {
      if (hasStringProp(e, "message") && notificationRef.current?.id) {
        if (isVideoAutoPlayError(e.message)) {
          hideNotification(notificationRef.current.id);
          return;
        }
        updateNotification({
          type: "error",
          id: notificationRef.current.id,
          description: t("fileViewer_error"),
        });
        errorStore.captureError({ error: e.message, source: "user" });
      }
    },
    [t, updateNotification, hideNotification],
  );

  const onFileLoaded = useCallback(() => {
    hideNotification(notificationRef.current!.id);
  }, [hideNotification]);

  const onViewerLoadError = useCallback(() => {
    updateNotification({
      type: "error",
      id: notificationRef.current!.id,
      description: t("fileViewer_load_error"),
    });
  }, [t, updateNotification]);

  useEffect(() => {
    notificationRef.current = showNotification({
      type: "loading",
      description: t("fileViewer_opening_pdf"),
    });
    trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
  }, [t, showNotification]);

  const hideCurrentNotification = useCallback(() => {
    if (notificationRef.current?.id) {
      hideNotification(notificationRef.current.id);
    }
  }, [hideNotification]);

  const videoViewer = useCallback(
    () =>
      viewer.current && file && file.url
        ? WebViewer(
            {
              path: PDF_TRON_SOURCE,
              initialDoc: file.url,
              disabledElements: ["ribbons", "toolsHeader", "toggleNotesButton", "selectToolButton"],
            },
            viewer.current,
          )
            .then(async (instance) => {
              const license = process.env.REACT_APP_PDF_TRON;
              instance.UI.addEventListener(instance.UI.Events.DOCUMENT_LOADED, onFileLoaded);
              instance.UI.addEventListener(instance.UI.Events.LOAD_ERROR, onViewerLoadError);
              instance.Core.disableEmbeddedJavaScript();
              // Extends WebViewer to allow loading HTML5 videos (.mp4, ogg, webm).
              const { loadVideo } = await initializeVideoViewer(instance, { license });
              if (file.url && file.type === "mp4") loadVideo(file.url);
              if (notificationRef.current?.id) {
                hideNotification(notificationRef.current.id);
              }
            })
            .catch(onFileError)
        : null,
    [file, onFileError, onFileLoaded, onViewerLoadError, hideNotification],
  );

  useEffect(() => {
    if (!file) return;
    videoViewer();
    return hideCurrentNotification;
  }, [file, hideCurrentNotification, videoViewer]);

  return {
    viewer,
    file,
    setFile,
    showNotification,
    onFileLoaded,
    onFileError,
    hideCurrentNotification,
  };
};

export default useFileViewer;
