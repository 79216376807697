import { create, insertMultiple, search, removeMultiple } from "@orama/orama";
import { persist, restore } from "@orama/plugin-data-persistence";
import localforage from "localforage";

import { stopwords as englishStopwords, SEARCH_DB_KEY } from "./localsearch.data";
import {
  AppLevelSection,
  EolasFile,
  eolasLogger,
  EolasMainSection,
  isEolasShadowCopyFile,
  MasterSearchFile,
  MedusaItem,
  MedusaSectionWithItems,
  OrganisationLevelSection,
  sectionStore,
} from "@eolas-medical/core";
import { LocalFilesSearchDb, LocalSearchFile, localSearchSchema } from "./localSearch.types";

import {
  makeChecklistMasterSearchFile,
  makeMedusaLocalSearchFile,
  makeMedusaMasterSearchFile,
} from "Components/MasterSearch/helpers";
import { eolasFileNormaliser } from "Utilities/helpers";
import { LDFlags } from "Utilities/types";
import { getOriginalFromShadowCopy } from "shared/pages/ContentRepository/ContentItems/functions/getOriginalFromShadowCopy";
import { sectionToFlags } from "Hooks";
import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";

// The types in the persistence library are different, so we have to use this and cast:

type AnyOrama = Parameters<typeof persist>[0];

const englishStopwordsMap: Record<string, string> = englishStopwords.reduce<Record<string, string>>(
  (acc, current) => {
    return { ...acc, [current]: current };
  },
  {},
);

export const formatSearchString = (str: string) => {
  if (!str) {
    return "";
  }
  const whiteSpaceTrimmed = str.replace(/\s\s+/g, " ").trim();
  const wordsAsArr = whiteSpaceTrimmed.split(" ");
  const wordsWithoutStopwords: string[] = [];
  for (const item of wordsAsArr) {
    if (!englishStopwordsMap[item]) {
      wordsWithoutStopwords.push(item);
    }
  }
  const withoutDuplicates = Array.from(new Set(wordsWithoutStopwords));
  return withoutDuplicates.join(" ");
};

export const removePersistedDb = () => {
  localforage.removeItem(SEARCH_DB_KEY);
};

export const createLocalFilesDb = async ({ isNew }: { isNew: boolean }) => {
  let db: LocalFilesSearchDb = await create({
    schema: localSearchSchema,
  });
  if (isNew) {
    localforage.removeItem(SEARCH_DB_KEY);
    return db;
  }
  const data = await localforage.getItem(SEARCH_DB_KEY);
  if (typeof data === "string") {
    db = (await restore("json", data)) as unknown as LocalFilesSearchDb;
  }
  return db;
};

export const persistSearchDb = async (db: LocalFilesSearchDb) => {
  try {
    const dbTypeForced = db as unknown as AnyOrama;
    const data = await persist(dbTypeForced, "json");
    if (typeof data === "string") {
      await localforage.setItem(SEARCH_DB_KEY, data);
    }
  } catch (error) {
    eolasLogger.error(new Error("Persist Local Files DB: unable to persist"), { error });
  }
};

export const populateLocalFilesDb = async ({
  searchDb,
  checklistIdsAdded = [],
  checklistIdsRemoved = [],
  filesAdded = [],
  fileIdsRemoved = [],
  medusaSectionsWithItems = [],
  isInitialPopulation,
}: {
  searchDb: LocalFilesSearchDb;
  checklistIdsRemoved?: string[];
  checklistIdsAdded?: string[];
  filesAdded?: EolasFile[];
  fileIdsRemoved?: string[];
  medusaSectionsWithItems?: MedusaSectionWithItems[];
  isInitialPopulation?: boolean;
}) => {
  if (
    !filesAdded.length &&
    !fileIdsRemoved.length &&
    !checklistIdsAdded.length &&
    !checklistIdsRemoved.length &&
    !medusaSectionsWithItems.length
  ) {
    return;
  }

  const allLocalSearchFiles: LocalSearchFile[] = [];

  const medusaItems: MedusaItem[] = medusaSectionsWithItems.reduce<MedusaItem[]>((acc, value) => {
    return [...acc, ...value.items];
  }, []);

  const totalToRemoveBeforeAdd = [
    ...fileIdsRemoved,
    ...filesAdded.map(({ id }) => id),
    ...checklistIdsAdded,
    ...checklistIdsRemoved,
    ...medusaItems.map(({ id }) => id),
  ];

  if (totalToRemoveBeforeAdd.length && !isInitialPopulation) {
    await removeMultiple(searchDb, totalToRemoveBeforeAdd);
  }

  for (const file of filesAdded) {
    if (isFileForDb(file)) {
      allLocalSearchFiles.push(mapToLocalSearchFile(file));
    }
  }

  for (const id of checklistIdsAdded) {
    const checklist = sectionStore.checklistTemplatesMap[id];
    if (checklist) {
      const checklistFile = makeChecklistMasterSearchFile({ checklist });
      if (checklistFile) {
        allLocalSearchFiles.push(mapToLocalSearchFile(checklistFile));
      }
    }
  }

  for (const section of medusaSectionsWithItems) {
    section.items.forEach((item) => {
      if (!item.deleted) {
        allLocalSearchFiles.push(
          makeMedusaLocalSearchFile({ medusaItem: item, medusaSectionName: section.name }),
        );
      }
    });
  }

  if (allLocalSearchFiles.length) {
    await insertMultiple(searchDb, allLocalSearchFiles);
  }
};

export const searchWithDb = async ({
  term,
  ldFlags,
  localFilesDb,
  isInAdminMode,
  shouldSearchSpace = true,
  shouldSearchOrganisation,
  idsToInclude,
}: {
  term: string;
  localFilesDb: LocalFilesSearchDb;
  ldFlags: LDFlags;
  isInAdminMode: boolean;
  shouldSearchSpace?: boolean;
  shouldSearchOrganisation: boolean;
  idsToInclude?: Record<string, string>;
}) => {
  if (!term.length) {
    return [];
  }

  const masterSearchFiles: MasterSearchFile[] = [];

  const result = await search<LocalFilesSearchDb, LocalSearchFile>(localFilesDb, {
    term,
    properties: "*",
    threshold: 0.4,
    boost: { name: 4, searchField: 1 },
    limit: idsToInclude ? 100 : 50,
  });

  for (const { document } of result.hits) {
    if (idsToInclude && !idsToInclude[document.id]) {
      continue;
    }

    const eolasFile = await contentDbStore.getItem(document.id);

    let masterSearchFile: MasterSearchFile | null = eolasFile
      ? { ...eolasFile, parentName: getParentName(eolasFile) }
      : null;

    if (!masterSearchFile) {
      masterSearchFile = makeChecklistMasterSearchFile({ id: document.id });
    }

    if (!masterSearchFile) {
      masterSearchFile = makeMedusaMasterSearchFile({ id: document.id });
    }

    if (!masterSearchFile) {
      continue;
    }

    const { file } = eolasFileNormaliser(masterSearchFile);

    if (file.isDraft && !isInAdminMode) {
      continue;
    }

    const isMainSectionEnabled =
      sectionStore.getChildReferenceOfSection(file.mainSectionID)?.disabled !== "true";

    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);

    const flagProperty = mainSectionType ? sectionToFlags[mainSectionType] : null;

    const isLdFlagEnabled = flagProperty !== null && ldFlags[flagProperty] === true;

    const mainSectionIdentity = sectionStore.getMainSectionIdentityByMainSectionId(
      file.mainSectionID,
    );

    const isGenericContentRepositoryItem = mainSectionIdentity === "genericContentRepository";

    let shouldInclude = isMainSectionEnabled && (isLdFlagEnabled || isGenericContentRepositoryItem);

    if (!shouldInclude) {
      continue;
    }

    const { isSpaceFile, isOrgFile } = getFileOwnerTypes(mainSectionType, file.mainSectionID);
    shouldInclude = (isSpaceFile && shouldSearchSpace) || (isOrgFile && shouldSearchOrganisation);

    if (shouldInclude) {
      masterSearchFiles.push(masterSearchFile);
    }
  }

  const includedOriginalIdMap: Record<string, string> = {};

  const finalResult: MasterSearchFile[] = [];

  for (const masterSearchFile of masterSearchFiles) {
    const { file } = eolasFileNormaliser(masterSearchFile);
    if (isEolasShadowCopyFile(file)) {
      const originalFile = await getOriginalFromShadowCopy(file, false);
      if (!originalFile || includedOriginalIdMap[originalFile.id]) {
        continue;
      }
      includedOriginalIdMap[originalFile.id] = originalFile.id;
    } else if (includedOriginalIdMap[file.id]) {
      continue;
    }
    finalResult.push(masterSearchFile);
  }

  return finalResult;
};

const getFileOwnerTypes = (
  mainSectionType: EolasMainSection | null,
  mainSectionId: string,
): { isSpaceFile: boolean; isOrgFile: boolean } => {
  if (!mainSectionType) {
    if (sectionStore.departmentSectionsOrder.find(({ id }) => id === mainSectionId)) {
      return { isSpaceFile: true, isOrgFile: false };
    }
    if (sectionStore.hospitalSectionsOrder.find(({ id }) => id === mainSectionId)) {
      return { isSpaceFile: false, isOrgFile: true };
    }
    return { isSpaceFile: false, isOrgFile: false };
  }
  if (Object.values<string>(AppLevelSection).includes(mainSectionType)) {
    return { isSpaceFile: true, isOrgFile: false };
  }
  if (Object.values<string>(OrganisationLevelSection).includes(mainSectionType)) {
    return { isSpaceFile: false, isOrgFile: true };
  }
  return { isSpaceFile: false, isOrgFile: false };
};

// We should never be searching for completed checklists (this should be applied pre DB population)
const isFileForDb = (file: EolasFile) => {
  const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(file.mainSectionID);
  return mainSectionType !== AppLevelSection.checklists;
};

const getParentName = <T extends MasterSearchFile>(file: T): string => {
  if (!file.parentID || file.parentID === file.mainSectionID) {
    return "";
  }
  return sectionStore.getChildReferenceOfSection(file.parentID)?.name ?? "";
};

const mapToLocalSearchFile = <T extends MasterSearchFile>(file: T): LocalSearchFile => {
  let searchField = file.searchField;

  if (!searchField) {
    searchField = `${file.name} ${getParentName(file)} ${
      file.keywords?.length ? file.keywords.join(" ") : ""
    }`;
  }

  return { name: file.name ?? "", searchField, id: file.id ?? "" };
};
