import { MutableRefObject } from "react";
import {
  ChecklistRecord,
  MasterSearchFile,
  sectionStore,
  EolasFile,
  MasterSearchPayload,
  hasStringProp,
  isCommunityLevelSection,
  isDefined,
  eolasLogger,
  fromAwsJSON,
  hasProp,
  MedusaItem,
  OrganisationLevelSection,
  MedusaSectionWithItems,
} from "@eolas-medical/core";
import { KnowledgeResult, NationalBodyTextColor, SearchTypeSection } from "./types";
import { isBnfMetadata } from "Hooks/useSelectFileModal/typeguards";
import { LocalSearchFile } from "Stores/localSearch/localSearch.types";

export const makeChecklistMasterSearchFile = (
  params:
    | {
        checklist: ChecklistRecord;
      }
    | { id: string },
): MasterSearchFile | null => {
  const checklistTemplate = hasProp(params, "checklist")
    ? params.checklist
    : sectionStore.getSingleChecklistTemplate(params.id);

  if (!checklistTemplate) {
    return null;
  }

  const ref = sectionStore.getChildReferenceOfSection(checklistTemplate.id);

  if (ref?.disabled === "true") {
    return null;
  }

  const {
    updatedAt,
    mainSectionID,
    highlighted: isHighlighted,
    ownerID,
    parentID,
    sharedID,
  } = sectionStore.getSection(checklistTemplate.id) ?? {};

  return {
    id: checklistTemplate.id,
    name: checklistTemplate.checklistName || "",
    searchField: `checklist ${checklistTemplate.checklistName}`,
    singleChecklist: checklistTemplate,
    updatedAt: updatedAt ? updatedAt : undefined,
    mainSectionID,
    highlighted: isHighlighted ? isHighlighted : undefined,
    ownerID,
    parentID: parentID ?? "",
    sharedID,
  };
};

export const findMedusaItemAndSection = (
  itemId?: string | null,
):
  | { maybeMedusaSection: MedusaSectionWithItems; maybeMedusaItem: MedusaItem }
  | { maybeMedusaSection: null; maybeMedusaItem: null } => {
  if (!itemId) {
    return { maybeMedusaSection: null, maybeMedusaItem: null };
  }

  for (const section of sectionStore.medusaSectionsWithItems) {
    // Find the index of the item in the section's items array
    const item = section.items.find(({ id }) => id === itemId);

    // If the item is found, return the section and index
    if (item) {
      return { maybeMedusaSection: section, maybeMedusaItem: item };
    }
  }

  // If no section or item is found, return null values
  return { maybeMedusaSection: null, maybeMedusaItem: null };
};

export const makeMedusaLocalSearchFile = ({
  medusaItem,
  medusaSectionName,
}: {
  medusaItem: MedusaItem;
  medusaSectionName: string;
}): LocalSearchFile => {
  return {
    id: medusaItem.id,
    name: medusaItem.name,
    searchField: `${medusaItem.name} ${medusaSectionName}`,
  };
};

export const makeMedusaMasterSearchFile = ({ id }: { id: string }): MasterSearchFile | null => {
  const { maybeMedusaItem, maybeMedusaSection } = findMedusaItemAndSection(id);

  if (!maybeMedusaSection) return null;

  const medusaSectionName = maybeMedusaSection.name;

  return {
    ...makeMedusaLocalSearchFile({ medusaItem: maybeMedusaItem, medusaSectionName }),
    mainSectionID: sectionStore.getChildReferenceByMainSectionType(
      OrganisationLevelSection.medusaMedications,
    )?.id,
    ownerID: sectionStore.organisationID,
    parentID: maybeMedusaItem.sectionId,
    parentName: medusaSectionName,
    deleted: "false",
    key: maybeMedusaItem.url.value,
  };
};

export const getResultsPosition = (dropdownRef: MutableRefObject<HTMLDivElement | null>) => {
  const searchboxOffset = dropdownRef.current?.getBoundingClientRect();

  if (searchboxOffset) {
    const top = searchboxOffset.top + searchboxOffset.height - 20;
    const left = searchboxOffset.left;
    const width = searchboxOffset.width;

    return { top, left, width };
  }

  return { top: 0, left: 0, width: 100 };
};

export const mapKnowledgeFiles = (files: EolasFile[]): KnowledgeResult[] => {
  const knowledgeFiles = files.map((f) => {
    const type = sectionStore.getMainSectionTypeFromMainSectionID(f.mainSectionID);

    // This should never happen, it's mainly for typescript but log a console warning just in case
    if (!type) {
      return null;
    }

    // This should never happen, it's mainly for typescript but log a console warning just in case
    if (!isCommunityLevelSection(type)) {
      eolasLogger.warn(
        `File has an non community level main section type: ${type} - INVESTIGATE!`,
        { fileId: f.id },
      );
      return null;
    }

    const mainSection = f.mainSectionID
      ? sectionStore.getMainSectionChildReference(f.mainSectionID)
      : undefined;
    const fileMetadata = f.metadata ? fromAwsJSON(f.metadata) : undefined;

    let nationalBody = hasStringProp(fileMetadata, "nationalBody")
      ? fileMetadata.nationalBody
      : undefined;

    const metadata = f.metadata ? fromAwsJSON(f.metadata) : null;

    const hasAnswerSnippet = hasStringProp(metadata, "answer") && Boolean(metadata.answer);
    const answer = hasAnswerSnippet ? metadata.answer : "";
    const hasSummaryText = hasStringProp(metadata, "summary") && Boolean(metadata.summary);
    const summary = hasSummaryText ? metadata.summary : "";

    let nationalBodyTextColor: NationalBodyTextColor = "grey-600";

    if (isBnfMetadata(metadata)) {
      nationalBodyTextColor = metadata.medicationMonographType === "BNF" ? "blue-500" : "red-500";
      nationalBody =
        metadata.medicationMonographType === "BNF"
          ? "Adult Medications and BNF"
          : "Paediatric Medications and BNFC";
    }

    return {
      id: f.id,
      title: f.name || "",
      mainSectionName: mainSection?.name || "",
      searchTypeSection: "knowledge" as SearchTypeSection,
      searchSubType: type,
      iconName: type ? type : undefined,
      knowledgeFile: f,
      nationalBody,
      nationalBodyTextColor,
      answer,
      summary,
      type: f.type,
    };
  });

  return knowledgeFiles.filter(isDefined);
};

/**
 * This function maps the search type to the analytics file identity used in the analytics payload
 * This mapping is required as we have changed the name of the searchType from "community" to "knowledge" and "KnowledgeCopilot"
 * however we still want to send "community" as the file identity in the analytics payload to keep analytics consistent
 */
export const searchTypeToAnalyticsFileIdentity = (
  searchType: SearchTypeSection,
): MasterSearchPayload["fileIdentity"] => {
  switch (searchType) {
    case "knowledge":
    case "knowledgeCopilot":
      return "community";
    case "local":
      return "local";
  }
};

/**
 * This function maps the search type to the search endpoint used in the analytics payload
 */
export const searchTypeToSearchEndpointUsed = (
  searchType: SearchTypeSection,
): MasterSearchPayload["searchEndpointUsed"] => {
  switch (searchType) {
    case "knowledge":
      return "nationalResources";
    case "knowledgeCopilot":
      return "copilot";
    case "local":
      // we return undefined here because we don't want to send an endpoint used for local searches
      return undefined;
  }
};
