import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  CommunityLevelSection,
  sectionStore,
  NationalResourceEntityType,
  ChildReference,
  EolasFile,
  AnalyticsEvents,
  NationalResourcesClickPayload,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Modal } from "UIKit";

import { compact, snakeCase } from "lodash";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import {
  EOLAS_CALCULATOR,
  CommunitySectionType,
  EOLAS_GENT_CALC_ICON_NAME,
  EOLAS_CR_CL_CALC_ICON_NAME,
  EOLAS_BODY_WEIGHT_CALC_ICON_NAME,
} from "../types";
import { SearchSortList } from "shared/components/Lists";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { useSelectFile } from "Hooks";
import { trackEvent } from "API/Analytics";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";

const ClinicalCalculatorsBody: React.FC<RouteComponentProps> = observer(() => {
  const { onSelectFile } = useSelectFile();
  const [searchInput, setSearchInput] = useState("");
  // FIXME: Sort this any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchItems, setSearchItems] = useState<any>([]);
  const [subSections, setSubSections] = useState<ChildReference[]>([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const { flags } = useLaunchDarkly();

  const isEolasGentCalcEnabled = flags[LDFlagNames.EOLAS_GENTAMICIN_CALC];
  const isEolasCreatinineCalcEnabled = flags[LDFlagNames.EOLAS_CREATININE_CLEARANCE_CALC];
  const isEolasIdealBodyWeightCalcEnabled = flags[LDFlagNames.EOLAS_IDEAL_BODY_WEIGHT_CALC];

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

  const history = useHistory();

  const clinicalCalcsMainSectionID = sectionStore
    .getMainSectionList("community")
    .find(({ icon }) => icon === CommunityLevelSection.clinicalCalculators)?.id;

  const items = useMemo(() => {
    return subSections
      .filter(({ icon }) => {
        // This is to hide eolas calculators that have been excluded for the current organisation
        if (icon && sectionStore.excludedEolasCalculatorIcons.includes(icon)) {
          return false;
        }

        if (icon === EOLAS_GENT_CALC_ICON_NAME && !isEolasGentCalcEnabled) {
          return false;
        }

        if (icon === EOLAS_CR_CL_CALC_ICON_NAME && !isEolasCreatinineCalcEnabled) {
          return false;
        }

        if (icon === EOLAS_BODY_WEIGHT_CALC_ICON_NAME && !isEolasIdealBodyWeightCalcEnabled) {
          return false;
        }

        return true;
      })
      .map(({ id, name, description, icon }) => {
        return {
          id,
          name: name || "",
          description: description || "Eolas Calculator",
          icon: icon || "",
        };
      });
  }, [
    subSections,
    isEolasGentCalcEnabled,
    isEolasCreatinineCalcEnabled,
    isEolasIdealBodyWeightCalcEnabled,
  ]);

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};

  const { t } = useTranslation();

  const { searchNationalResources, searchingNationalResources } = useSearchNationalResources();

  const { searchResult, onSetSearchInput } = useLocalSearch({
    data: searchItems,
    keysToSearch: ["name"],
  });

  const handleLocalSearch = useCallback(() => {
    setShowSearchResult(true);
    onSetSearchInput(searchInput);
  }, [onSetSearchInput, searchInput]);

  const handleClickCalculator = React.useCallback(
    (calculator: EolasFile) => {
      const calculatorName = snakeCase(calculator.name as string);

      trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
        nationalResourceType: NationalResourceEntityType.CALCULATOR,
        resourceName: calculator.name,
      } as NationalResourcesClickPayload);

      if (body === EOLAS_CALCULATOR) {
        history.push({ pathname: `eolas_calculator/${calculatorName}/${calculator.id}` });
      } else {
        onSelectFile(calculator);
      }
    },
    [body, history, onSelectFile],
  );

  const handleClearSearch = useCallback(() => {
    setSearchInput("");
  }, []);

  useEffect(() => {
    if (body === EOLAS_CALCULATOR) {
      setSearchItems(items);
    } else {
      searchNationalResources(
        {
          entityType: NationalResourceEntityType.CALCULATOR,
          query: body,
          mode: "searchByNationalBody",
        },
        {
          onSuccess: (response) => {
            setSearchItems(response);
          },
        },
      );
    }
  }, [body, searchNationalResources, items]);

  useEffect(() => {
    const clinicalCalcsChildrenOrder = sectionStore.getSection(
      clinicalCalcsMainSectionID!,
    ).childrenOrder;
    if (clinicalCalcsChildrenOrder && clinicalCalcsChildrenOrder.length) {
      setSubSections(compact(clinicalCalcsChildrenOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchInput === "") {
      setShowSearchResult(false);
    }
  }, [searchInput]);

  const renderItem = React.useCallback(
    (eolasFile: EolasFile) => {
      return (
        <CommunityListItem
          eolasFile={eolasFile}
          onSelectFile={handleClickCalculator}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          onShareFile={
            isShareFileEnabled
              ? () =>
                  handleOnShareFile(eolasFile, {
                    mainSectionIdOverride: clinicalCalcsMainSectionID,
                  })
              : undefined
          }
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [
      handleClickCalculator,
      handleOnShareFile,
      isShareFileEnabled,
      clinicalCalcsMainSectionID,
      showRemoveFavouriteModal,
    ],
  );

  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle title={t("clinicalCalculators_title")} />
        <NavButton onClick={() => history.goBack()} />
        <SearchSortList<EolasFile>
          value={searchInput}
          placeholderSearchText={t("findClinicalCalculator_searchPlaceholder")}
          items={showSearchResult ? searchResult : searchItems}
          isSearchable
          searchType="click"
          isLoading={searchingNationalResources}
          onSearchInputChange={setSearchInput}
          onClearSearch={handleClearSearch}
          onClickSearch={handleLocalSearch}
          renderItem={renderItem}
        />
      </InnerPageWrapper>
    </>
  );
});

export default ClinicalCalculatorsBody;
