import {
  AnalyticsEvents,
  AppLevelSection,
  CompletedContentStatus,
  DraftFile,
  EolasFile,
  EolasMainSection,
  OrganisationLevelSection,
  isEolasFile,
  isFileCompleted,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { FileViewerComponent } from "Components";
import { useDownloadFile } from "Components/EolasFileList/hooks";
import { useFileViewer } from "Components/FileViewer/useFileViewer";
import { useCompleteFile } from "Hooks";
import { Button, Modal, NavButton } from "UIKit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";

interface DocumentViewerProps {
  document: EolasFile | DraftFile;
  backBehaviour?: "goBack" | "goHome";
  startingPage?: number;
  versionNo?: number;
  shadowCopyContentId?: string;
  originalFileId?: string | null;
  shouldShowBreadcrumbs?: boolean;
}

const fileTrackingSections = [
  AppLevelSection.educationPortal,
  AppLevelSection.newsFeed,
  OrganisationLevelSection.learningManagement,
  OrganisationLevelSection.communicationPortal,
] as EolasMainSection[];

const DocumentViewer = ({
  document,
  backBehaviour = "goBack",
  startingPage,
  versionNo,
  shadowCopyContentId,
  originalFileId,
  shouldShowBreadcrumbs,
}: DocumentViewerProps) => {
  const { t } = useTranslation();
  const { saveFile } = useDownloadFile(document);
  const { onCompleteFile } = useCompleteFile();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });

  const mainSection = document.mainSectionID
    ? sectionStore.getMainSectionTypeFromMainSectionID(document.mainSectionID)
    : null;

  const mainSectionIdentity = document.mainSectionID
    ? sectionStore.getMainSectionIdentityByMainSectionId(document.mainSectionID)
    : null;

  // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
  const idForTracking = mainSection ?? mainSectionIdentity;

  const { viewer, shareModal, isShareModalOpen, onClickBack } = useFileViewer({
    document,
    startingPage,
    versionNo,
    handleGoBack,
  });

  useEffect(() => {
    if (idForTracking) {
      trackEvent(AnalyticsEvents.OPEN_FILE, {
        mainSectionId: idForTracking,
        fileId: document.id,
        fileType: document.type || "",
        fileName: document.name,
      });
    }

    if (mainSection && !fileTrackingSections.includes(mainSection)) {
      return;
    }

    const isAdmin = sectionStore.isAdmin;
    if (isAdmin) return;

    const isCompleted = isEolasFile(document) ? isFileCompleted(document) : false;
    if (isCompleted) return;

    onCompleteFile(decodeURIComponent(document.id), CompletedContentStatus.SEEN);
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mainSectionId, parentId, fileName } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : originalFileId || document.id,
  });

  return (
    <>
      <Modal hasCloseIcon={false} overlayColor="bg-grey-light" open={true} onClose={handleGoBack}>
        <div className="h-100vh w-100vw">
          <div className="bg-grey-light py-1 px-2 flex flex-col justify-center gap-2">
            {shouldShowBreadcrumbs ? (
              <FileBreadcrumbs
                shouldShowSpaceName={true}
                mainSectionId={mainSectionId}
                parentId={parentId}
                fileName={fileName}
              />
            ) : null}
            <div className="flex justify-center items-center space-x-3">
              <NavButton onClick={onClickBack} />
              <Button size="sm" onClick={saveFile}>
                {t("general_download_original")}
              </Button>
            </div>
          </div>

          <div className="bg-grey-light" style={{ height: "94%" }}>
            {document.key ? <FileViewerComponent viewer={viewer} /> : null}
          </div>
        </div>
        {isShareModalOpen ? shareModal : null}
      </Modal>
    </>
  );
};

export default DocumentViewer;
