import { useSpacesContext } from "modules/spaces";
import ContentSections from "shared/pages/ContentRepository/ContentSections/ContentSections";
import useSpaceContentRepositoryParams from "../../hooks/useSpaceContentRepositoryParams";
import { useRecordMainSectionVisit } from "Hooks";

const SpaceContentSections = () => {
  const { contentRepositoryId } = useSpaceContentRepositoryParams();
  const { isAdmin } = useSpacesContext();

  useRecordMainSectionVisit({ mainSectionId: contentRepositoryId, visitFrom: "directNavigation" });

  return (
    <ContentSections
      contentRepositoryId={contentRepositoryId}
      isAdmin={isAdmin}
      showExpiredItems
      variant="titleAndIcon"
    />
  );
};

export default SpaceContentSections;
